import axios from "axios"
import algosdk, { LogicSigAccount, signLogicSigTransaction, encodeUint64} from "algosdk"
import { toast } from "react-toastify"
import { formatJsonRpcRequest } from "@json-rpc-tools/utils"
import WalletConnect from "@walletconnect/client"
import WalletConnectQRCodeModal from "algorand-walletconnect-qrcode-modal";
import { algodClient, myAlgoWallet } from "./connections"
import { useAccountContext } from "src/context/context"
import { IAssetData, IPoolInfo } from "src/types"
import { a1, AssetIDs, BASE_URL, BURN_ARGS, MICROALGOS_TO_ALGOS_RATIO, MINT_ARGS, paramsWithFee, SWAP_ARGS } from "src/constants"
import { AMM_ID, CENTRAL_ADDR, COMMUNITY_TOKEN } from "../constants"

export const shortenAddress = (address: string) => `${address.slice(0, 5)}...${address.slice(-4)}`

export const callAPI = async (coin: string, currency: string, days: string, interval?: string) => {
  return await axios.get(`https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=${currency}&days=${days}${interval && `&interval=${interval}`}`)
}

export async function apiGetAccountAssets(
  address: string,
): Promise<IAssetData[]> {

  const accountInfo = await algodClient
    .accountInformation(address)
    .setIntDecoding(algosdk.IntDecoding.BIGINT)
    .do();

  const algoBalance = accountInfo.amount;
  const assetsFromRes: Array<{
    "asset-id": bigint;
    amount: bigint;
    creator: string;
    frozen: boolean;
  }> = accountInfo.assets;

  const assets: IAssetData[] = assetsFromRes.map(({ "asset-id": id, amount, creator, frozen }) => ({
    id: Number(id),
    amount,
    creator,
    frozen,
    decimals: 0,
  }));

  assets.sort((a, b) => a.id - b.id);

  await Promise.all(
    assets.map(async asset => {
      const { params } = await algodClient.getAssetByID(asset.id).do();
      asset.name = params.name;
      asset.unit_name = params["unit-name"];
      asset.url = params.url;
      asset.decimals = params.decimals;
    }),
  );

  assets.unshift({
    id: 0,
    amount: algoBalance,
    creator: "",
    frozen: false,
    decimals: 6,
    name: "Algo",
    unit_name: "Algo",
  });

  return assets;
}

export const waitForTransaction = async (txId: string) => {
  let lastStatus = await algodClient.status().do();
  console.log('laststatus:', lastStatus)
  let lastRound = lastStatus["last-round"]
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const status = await algodClient.pendingTransactionInformation(txId).do();
    console.log('status:', status)
    if (status["pool-error"]) {
      throw new Error(`Transaction Pool Error: ${status["pool-error"]}`);
    }
    if (status["confirmed-round"]) {
      return status["confirmed-round"];
    }
    lastStatus = await algodClient.statusAfterBlock(lastRound + 1).do();
    lastRound = lastStatus["last-round"];
  }
};

export const fetchGetApi = async (query: string, withAuth?: boolean) => {
  try {
    const response = await fetch(`${BASE_URL}/${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      toast.error("Sorry the server is not available right now please try later");
    }
  } catch (error) {
    console.log(error);
  }
}

export const fetchPostApi = async (query: string, body: any, withAuth?: boolean) => {
  try {
    const response = await fetch(`${BASE_URL}/${query}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      toast.error("Sorry the server is not available right now please try later");
    }
  } catch (error) {
    console.log(error);
  }
}

export const fetchPutApi = async (query: string, body: any, withAuth?: boolean) => {
  try {
    const response = await fetch(`${BASE_URL}/${query}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 400) {
      const data = await response.json();
      return data;
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      toast.error("Sorry the server is not available right now please try later");
    }
  } catch (error) {
    console.log(error);
  }
}

export const SwapToken = async (amount: number | null, amount1: number | null, asset1: number, asset2: number, address: string, walletType: string, slippage: number, poolInfo: IPoolInfo, output: string,) => {
  try {
    if (!address) {
      toast.error('Please connect wallet')
      return
    }
    if (!amount) {
      toast.error('Input amount!')
      return
    }
    if (!algosdk.isValidAddress(output)) {
      toast.error('Output address is not valid!')
      return
    }


    let tx1: any
    const params = await algodClient.getTransactionParams().do()
    if (!poolInfo) {
      toast.error('Pool is not created yet.')
      return
    }
    
    amount = Math.round(amount ?? 0);
    amount1 = Math.round(amount1 ?? 0);
    console.log(amount + ":" + amount1)
    const temp = await fetchGetApi(`settings`)
    const CENTRAL_APP = temp.amm
    const POOL = poolInfo.addr
    if (poolInfo !== null) {
      if (asset1 === 0) {
        tx1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          from: address,
          to: POOL,
          amount: amount,
          suggestedParams: params
        })
      } else {
        tx1 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          from: address,
          to: POOL,
          assetIndex: asset1,
          amount: amount,
          suggestedParams: params
        })
      }
    } else {
      toast.error('Pool have not creted yet.')
      return
    }
    console.log(Math.round(Number(amount1) * ((100 - slippage) / 100) as number))
    const call = algosdk.makeApplicationNoOpTxnFromObject({
      appIndex: CENTRAL_APP,
      from: address,
      suggestedParams: paramsWithFee(params, 2),
      appArgs: [a1, encodeUint64(Math.round(Number(amount1) * ((100 - slippage) / 100) as number))],  // **IAN** THIS NEED TO BE REPLACED WITH MIN RECEIVED FROM SWAP
      accounts: [output, POOL],
      foreignAssets: [asset1, asset2, poolInfo.lp, COMMUNITY_TOKEN]
    })

    // eslint-disable-next-line prefer-const
    let txns = [tx1, call]


    if (walletType === 'myalgo') {
      const groupID = algosdk.computeGroupID(txns)

      for (let i = 0; i < 2; i++) {
        txns[i].group = groupID;
      }
      const signedTxns = await myAlgoWallet.signTransaction(txns.map(txn => txn.toByte()));
      console.log("txns:", txns, "SignedTxns:", signedTxns)
      const sg = signedTxns.map((tx: { blob: any }) => tx.blob)
      console.log('sg:', sg)
      try {
        const response = await algodClient.sendRawTransaction(sg).do();
        console.log(response)
      } catch (error) {
        console.log(error)
        return -1
      }

    } else if (walletType === 'algoSigner') {

      const group = algosdk.assignGroupID(txns)

      const base64Txs = group.map((binary) => window.AlgoSigner.encoding.msgpackToBase64(binary.toByte()));

      console.log('base64Txs:', base64Txs)

      const signedTxs = await window.AlgoSigner.signTxn([
        {
          txn: base64Txs[0],
        },
        {
          txn: base64Txs[1],
        },
      ]);

      const binarySignedTxs = signedTxs.map((tx: { blob: any; }) => window.AlgoSigner.encoding.base64ToMsgpack(tx.blob));
      console.log('binarySignedTxs:', binarySignedTxs);
      try {
        await algodClient.sendRawTransaction(binarySignedTxs).do();
      } catch (error) {
        return -1
      }

    } else if (walletType === 'peraWallet') {
    //   console.log("clicked")
    //   const connector = new WalletConnect({
    //     bridge: "https://bridge.walletconnect.org",
    //     qrcodeModal: WalletConnectQRCodeModal,
    //   });
    //   console.log(connector)
    //   const txnsToSign1 = [
    //     {
    //       txn: tx1,
    //       message: "This is a payment transaction that sends 0.1 Algos to yourself.",
    //     },
    //   ];

    //   const txnsToSign2 = [
    //     {
    //       txn: call,
    //       message: "This is a payment transaction that sends 0.1 Algos to yourself.",
    //     },
    //   ]

    //   const txnsToSign = [txnsToSign1, txnsToSign2]

    //   const flatTxns = txnsToSign.reduce((acc, val) => acc.concat(val), []);

    //   const walletTxns = flatTxns.map(
    //     ({ txn, message }) => ({
    //       txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64"),
    //       message,
    //     }),
    //   );

    //   const requestParams = [walletTxns];

    //   const request = formatJsonRpcRequest("algo_signTxn", requestParams);
    //   const result: any = await connector.sendCustomRequest(request);
    //   console.log(result)
    //   const indexToGroup = (index: number) => {
    //     for (let group = 0; group < txnsToSign.length; group++) {
    //       const groupLength = txnsToSign[group].length;
    //       if (index < groupLength) {
    //         return [group, index];
    //       }
    //       index -= groupLength;
    //     }

    //     throw new Error(`Index too large for groups: ${index}`);
    //   };
    //   const signedPartialTxns: Array<Array<Uint8Array | null>> = txnsToSign.map(() => []);
    //   result.forEach((r: any, i: any) => {
    //     const [group, groupIndex] = indexToGroup(i);
    //     const toSign = txnsToSign[group][groupIndex];

    //     const rawSignedTxn = Buffer.from(r, "base64");
    //     signedPartialTxns[group].push(new Uint8Array(rawSignedTxn));
    //   });

    //   const signedTxns = await signedPartialTxns.map(
    //     (signedPartialTxnsInternal, group) => {
    //       return signedPartialTxnsInternal.map((stxn, groupIndex) => {
    //         if (stxn) {
    //           return stxn;
    //         }
    //       });
    //     },
    //   );

    //   signedTxns.forEach(async (signedTxn: any, index) => {
    //     try {
    //       const { txId } = await algodClient.sendRawTransaction(signedTxn).do();

    //       console.log(txId)
    //     } catch (err) {
    //       console.error(`Error submitting transaction at index ${index}:`, err);
    //       return -1;
    //     }
    //   });
    const groupID = algosdk.computeGroupID(txns)

    for (let i = 0; i < txns.length; i++) {
      txns[i].group = groupID;
    }
    const connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org",
      qrcodeModal: WalletConnectQRCodeModal,
    });
    const txnsToSign = txns.map(txn => {
      const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64");

      return {
        txn: encodedTxn,
        message: 'Description of transaction being signed',
        // Note: if the transaction does not need to be signed (because it's part of an atomic group
        // that will be signed by another party), specify an empty singers array like so:
        // signers: [],
      };
    });

    const requestParams = [txnsToSign];
    console.log(requestParams)
    const request = formatJsonRpcRequest("algo_signTxn", requestParams);
    const result: any = await connector.sendCustomRequest(request);
    const decodedResult = result.map((element: any) => {
      return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
    });

    await algodClient.sendRawTransaction(decodedResult).do();
    }
    return txns.map((txn: any) => txn.txID())
  } catch (err: any) {
    console.log('Error', err, typeof(err), err.Error)
  }
  
}

export const mint = async (amount: number | null, amount1: number | null, asset1: number, asset2: number, address: string, walletType: string, feeTier: number, lpOut: number) => {
  try {
    if (!address) {
      toast.error('Please connect wallet')
      return
    }
    if (!amount || !amount1) {
      toast.error('Input amount!')
      return
    }
    amount = Math.round(amount ?? 0);
    amount1 = Math.round(amount1 ?? 0);
    console.log(amount)
    let tx1: any
    let p: number, s: number
    let response: any
    if (asset2 > asset1) {
      response = await fetchGetApi(`getPool?p=${asset2}&s=${asset1}&f=${feeTier}`)
      console.log(`getPool?p=${asset2}&s=${asset1}&f=${feeTier}`)
    } else {
      response = await fetchGetApi(`getPool?p=${asset1}&s=${asset2}&f=${feeTier}`)
      console.log(`getPool?p=${asset1}&s=${asset2}&f=${feeTier}`)
    }
    console.log("Pool:", response)
    const temp = await fetchGetApi(`settings`)
    const CENTRAL_APP = temp.amm
    console.log(JSON.stringify(response))
    const POOL = response.addr
    const params = await algodClient.getTransactionParams().do()
    let mtxn_1, mtxn_2;
    if (asset1 === 0) {
      mtxn_1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: address,
        to: POOL,
        amount: (amount),
        suggestedParams: params,
      })
    } else {
      mtxn_1 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: address,
        to: POOL,
        assetIndex: asset1,
        amount: (amount),
        suggestedParams: params,
      })
    }
    if (asset2 === 0) {
      mtxn_2 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: address,
        to: POOL,
        amount: Number(amount1),
        suggestedParams: params
      })
    } else {
      mtxn_2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: address,
        to: POOL,
        assetIndex: asset2,
        amount: Number(amount1),
        suggestedParams: params
      })
    }

    console.log(Math.floor(lpOut))
    const mtxn_3 = algosdk.makeApplicationNoOpTxnFromObject({
      from: address,
      appIndex: CENTRAL_APP,
      appArgs: [new Uint8Array(Buffer.from('mint')), encodeUint64(Math.floor(lpOut))],
      foreignAssets: [asset1, asset2, response.lp],
      accounts: [address, POOL],
      suggestedParams: paramsWithFee(params, 3)
    })
    // eslint-disable-next-line prefer-const
    let txns = [mtxn_1, mtxn_2, mtxn_3]

    if (walletType === 'myalgo') {
      const groupID = algosdk.computeGroupID(txns)

      for (let i = 0; i < 3; i++) {
        txns[i].group = groupID;
      }
      // const groupID = algosdk.computeGroupID(txns)

      // for (let i = 0; i < 3; i++) {
      //   txns[i].group = groupID;
      // }

      const signedTxns = await myAlgoWallet.signTransaction(txns.map(txn => txn.toByte()));
      console.log("txns:", txns, "SignedTxns:", signedTxns)
      const sg = signedTxns.map(tx => tx.blob)
      console.log('sg:', sg)
      const response = await algodClient.sendRawTransaction(sg).do();
      console.log("Swap", response)

    } else if (walletType === 'algoSigner') {

      const group = algosdk.assignGroupID(txns)

      const base64Txs = group.map((binary) => window.AlgoSigner.encoding.msgpackToBase64(binary.toByte()));

      console.log('base64Txs:', base64Txs)

      const signedTxs = await window.AlgoSigner.signTxn([
        {
          txn: base64Txs[0],
        },
        {
          txn: base64Txs[1],
        },
        {
          txn: base64Txs[2],
        },
      ]);

      const binarySignedTxs = signedTxs.map((tx: { blob: any; }) => window.AlgoSigner.encoding.base64ToMsgpack(tx.blob));
      console.log('binarySignedTxs:', binarySignedTxs);
      await algodClient.sendRawTransaction(binarySignedTxs).do();

    } else if (walletType === 'peraWallet') {
      const groupID = algosdk.computeGroupID(txns)

      for (let i = 0; i < 3; i++) {
        txns[i].group = groupID;
      }
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: WalletConnectQRCodeModal,
      });
      const txnsToSign = txns.map(txn => {
        const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64");

        return {
          txn: encodedTxn,
          message: 'Description of transaction being signed',
          // Note: if the transaction does not need to be signed (because it's part of an atomic group
          // that will be signed by another party), specify an empty singers array like so:
          // signers: [],
        };
      });

      const requestParams = [txnsToSign];
      console.log(requestParams)
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result: any = await connector.sendCustomRequest(request);
      const decodedResult = result.map((element: any) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });

      await algodClient.sendRawTransaction(decodedResult).do();
      

      

    }
  return txns.map((txn: any) => txn.txID())
  } catch (err: any) {
    console.log('Error', err, typeof(err))
    return -1
  }
}

export const burn = async (amount: number | null, asset1: number, asset2: number, address: string, walletType: string, feeTier: number, min1: number, min2: number) => {
  try {
    if (!address) {
      toast.error('Please connect wallet')
      return
    }
    if (!amount) {
      toast.error('Input amount!')
      return
    }
    let tx1: any
    let p: number, s: number, pOut: number, sOut: number
    p = asset1, pOut = min1
    s = asset2, sOut = min2
    if (asset1 < asset2) {
      p = asset2, pOut = min2
      s = asset1, sOut = min1
    }
    console.log(pOut, sOut)
    const response = await fetchGetApi(`getPool?p=${p}&s=${s}&f=${feeTier}`)
    if (response === null) {
      toast.error('Pool has not created yet.')
      return
    }
    console.log(amount)
    const temp = await fetchGetApi(`settings`)
    const CENTRAL_APP = temp.amm
    const POOL = response.addr
    const params = await algodClient.getTransactionParams().do()

    const brtxn_1 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: address,
      to: POOL,
      amount: (amount),
      assetIndex: response.lp,
      suggestedParams: params
    })

    const brtxn_2 = algosdk.makeApplicationNoOpTxnFromObject({
      from: address,
      appIndex: CENTRAL_APP,
      appArgs: [new Uint8Array(Buffer.from('burn')), encodeUint64(Math.floor(pOut)), encodeUint64(Math.floor(sOut))],
      foreignAssets: [asset1, asset2, response.lp], // **IAN** community token is not needed
      accounts: [address, POOL],
      suggestedParams: paramsWithFee(params, 3)
    })

    // eslint-disable-next-line prefer-const
    let txns = [brtxn_1, brtxn_2];

    const groupID = algosdk.computeGroupID(txns)

    for (let i = 0; i < 2; i++) {
      txns[i].group = groupID;
    }
    if (walletType === 'myalgo') {
      // const groupID = algosdk.computeGroupID(txns)

      // for (let i = 0; i < 2; i++) {
      //   txns[i].group = groupID;
      // }

      const signedTxns = await myAlgoWallet.signTransaction(txns.map(txn => txn.toByte()));
      console.log("txns:", txns, "SignedTxns:", signedTxns)
      const sg = signedTxns.map(tx => tx.blob)
      console.log('sg:', sg)
      await algodClient.sendRawTransaction(sg).do();

    } else if (walletType === 'algoSigner') {

      // const group = algosdk.assignGroupID(txns)

      const base64Txs = txns.map((binary) => window.AlgoSigner.encoding.msgpackToBase64(binary.toByte()));

      console.log('base64Txs:', base64Txs)

      const signedTxs = await window.AlgoSigner.signTxn([
        {
          txn: base64Txs[0],
        },
        {
          txn: base64Txs[1],
        },
      ]);
      console.log('signedTxs:', signedTxs);
      const binarySignedTxs = signedTxs.map((tx: { blob: any; }) => window.AlgoSigner.encoding.base64ToMsgpack(tx.blob));
      console.log('binarySignedTxs:', binarySignedTxs);
      await algodClient.sendRawTransaction(binarySignedTxs).do();
    } else if (walletType === 'peraWallet') {
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: WalletConnectQRCodeModal,
      });
      const txnsToSign = txns.map(txn => {
        const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64");

        return {
          txn: encodedTxn,
          message: 'Description of transaction being signed',
          // Note: if the transaction does not need to be signed (because it's part of an atomic group
          // that will be signed by another party), specify an empty singers array like so:
          // signers: [],
        };
      });

      const requestParams = [txnsToSign];

      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result: any = await connector.sendCustomRequest(request);
      const decodedResult = result.map((element: any) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });

      await algodClient.sendRawTransaction(decodedResult).do();
      //return signedTxns
    }
    return txns.map((txn: any) => txn.txID())
  } catch (err: any) {
    console.log(err)
    return -1
  }
}

export const createPool = async (asa0: number, asa1: number, address: string, walletType: string, feeTier: number) => {

  try {

    const sig = await generateLogicSig(asa0, asa1, feeTier)
    const p = asa0 > asa1 ? asa0 : asa1
    const s = asa0 > asa1 ? asa1 : asa0
    console.log(asa0, asa1)
    const POOL = sig.address()
    const params = await algodClient.getTransactionParams().do()

    console.log(address)
    const tx1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: address,
        suggestedParams: paramsWithFee(params, 1), //paramsWithFee 1
        to: POOL,
        amount: 1003000
    })

    const tx2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: POOL,
        suggestedParams: paramsWithFee(params, 1), //1
        to: POOL,
        amount: 0,
        assetIndex: p,
    })

    const tx3 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: POOL,
      suggestedParams: paramsWithFee(params, 1), // 1
      to: POOL,
      amount: 0,
      assetIndex: s,
    })

    const tx4 = algosdk.makeApplicationOptInTxnFromObject({
        from: POOL,
        suggestedParams: paramsWithFee(params, 3), // 2
        appIndex: AMM_ID,
        rekeyTo: CENTRAL_ADDR,
        appArgs: [encodeUint64(feeTier)],
        foreignAssets: [p,s]
    })

    let txns
    if(s === 0) {
      txns = [tx1, tx2, tx4]
    } else {
      txns = [tx1, tx2, tx3, tx4]
    }

    console.log(sig.address())
    const groupID = algosdk.computeGroupID(txns)
    console.log(groupID)
    for(let i = 0; i < txns.length; i++) {
      txns[i].group = groupID
    }
    let signedTxns: any[] = []
    txns.slice(1).map(async txn => {
      //console.log(await signLogicSigTransaction(txn, sig))
      signedTxns.push(signLogicSigTransaction(txn, sig))
    })
    if(walletType == "myalgo") {

      console.log("Signing")
      const signedTx1 = await myAlgoWallet.signTransaction(txns[0].toByte());
      signedTxns.unshift(signedTx1)

      // const poolSignedTxns = await signLogicSigTransaction(txns.slice(1).map(txn => txn.toByte()), sig);
      // console.log(poolSignedTxns)
      // const signedTxns = [signedTx1, poolSignedTxns]
      console.log(signedTxns)
      const sg = signedTxns.map(tx => tx.blob)
      console.log("Create Pool sg:", sg)
      try{ 
        const response = await algodClient.sendRawTransaction(sg).do();
      } catch (error: any) {
        console.log(error)
        return -1;
      }
      
      // const groupID = algosdk.computeGroupID(txns)

      // for (let i = 0; i < 2; i++) {
      //   txns[i].group = groupID;
      // }

      // const signedTxns = await myAlgoWallet.signTransaction(txns.map(txn => txn.toByte()));
      // console.log("txns:", txns, "SignedTxns:", signedTxns)
      // const sg = signedTxns.map(tx => tx.blob)
      // console.log('sg:', sg)
      // const response = await algodClient.sendRawTransaction(sg).do();
      // return signedTxns

    } else if(walletType == "algoSigner") {
      const base64Txs = txns.map((binary) => window.AlgoSigner.encoding.msgpackToBase64(binary.toByte()));

      console.log('base64Txs:', base64Txs)

      signedTxns.unshift(await window.AlgoSigner.signTxn(base64Txs.map((tx: any, index: number) => {return index == 0 ? {txn: tx} : {txn: tx, signers: []}})));

      signedTxns = signedTxns.map((tx: any, index: number) => {return (index == 0 ? tx[0] : tx)})
      console.log(signedTxns)

      const binarySignedTxs = signedTxns.map((tx: any, index: number) => index == 0 ? window.AlgoSigner.encoding.base64ToMsgpack(tx.blob) : tx.blob);
      console.log('binarySignedTxs:', binarySignedTxs);
      await algodClient.sendRawTransaction(binarySignedTxs).do();

    } else if(walletType == "peraWallet") {
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: WalletConnectQRCodeModal,
      });
      const txnsToSign = txns.map(txn => {
        const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64");

        return {
          txn: encodedTxn,
          message: 'Description of transaction being signed',
          // Note: if the transaction does not need to be signed (because it's part of an atomic group
          // that will be signed by another party), specify an empty singers array like so:
          // signers: [],
        };
      });

      // const txnsToSign = {
      //   txn: Buffer.from(algosdk.encodeUnsignedTransaction(txns[0])).toString("base64"),
      //   message: 'Description of transaction being signed',
      //   // Note: if the transaction does not need to be signed (because it's part of an atomic group
      //   // that will be signed by another party), specify an empty singers array like so:
      //   // signers: [],
      // }

      const requestParams = [txnsToSign];
      console.log(requestParams)
      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result: any = await connector.sendCustomRequest(request);
      // signedTxns.unshift(...result.blod)
      let decodedResult = result.map((element: any) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });
      decodedResult = [...decodedResult.filter((res: any) => res != null), ...signedTxns.map(tx => tx.blob)]
      console.log(decodedResult)
      await algodClient.sendRawTransaction(decodedResult).do();
    }
    return txns.map((txn: any) => txn.txID())
  } catch(err: any) {
    console.log(err)
    return -1
  }

}

export const optIn = async (assets: number[], address: string, walletType: string) => {
  try {
    const txns: any[] = [];
    const params = await algodClient.getTransactionParams().do()
    for(const asset of assets) {
      txns.push(algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: address,
        suggestedParams: paramsWithFee(params, 1), // 1
        to: address,
        amount: 0,
        assetIndex: asset,
      }))
    }
  
    if(walletType == "myalgo") {
      
      const signedTxns = await myAlgoWallet.signTransaction(txns.map(txn => txn.toByte()));
      const sg = signedTxns.map(tx => tx.blob)
      await algodClient.sendRawTransaction(sg).do();

      return signedTxns
  
    } else if(walletType == "algoSigner") {
      const base64Txs = txns.map((binary) => window.AlgoSigner.encoding.msgpackToBase64(binary.toByte()));

      console.log('base64Txs:', base64Txs)

      const signedTxs = await window.AlgoSigner.signTxn([
        {
          txn: base64Txs[0],
        },
        {
          txn: base64Txs[1],
        },
      ]);

      const binarySignedTxs = signedTxs.map((tx: { blob: any; }) => window.AlgoSigner.encoding.base64ToMsgpack(tx.blob));
      console.log('binarySignedTxs:', binarySignedTxs);
      await algodClient.sendRawTransaction(binarySignedTxs).do();
    } else if(walletType == "peraWallet") {
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: WalletConnectQRCodeModal,
      });
      const txnsToSign = txns.map(txn => {
        const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64");

        return {
          txn: encodedTxn,
          message: 'Description of transaction being signed',
          // Note: if the transaction does not need to be signed (because it's part of an atomic group
          // that will be signed by another party), specify an empty singers array like so:
          // signers: [],
        };
      });

      const requestParams = [txnsToSign];

      const request = formatJsonRpcRequest("algo_signTxn", requestParams);
      const result: any = await connector.sendCustomRequest(request);
      const decodedResult = result.map((element: any) => {
        return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
      });

      await algodClient.sendRawTransaction(decodedResult).do();
    }
    try {
      await waitForTransaction(txns[0].txID())
    } catch {
      return -1
    }
  } catch {
    return -1
  }
  

}

export const generateLogicSig = async (selected: number, selected1: number, feeTier: number) => {


  const p = selected > selected1 ? selected : selected1
  const s = selected > selected1 ? selected1 : selected

  const LOGICSIG_TEMPLATE = `#pragma version 6
    int ${p}
    int ${s}
    >
    int ${p}
    global GroupSize
    int 1
    -
    gtxnsa Assets 0
    ==
    &&
    int ${s}
    global GroupSize
    int 1
    -
    gtxnsa Assets 1
    ==
    &&
    txn CloseRemainderTo
    global ZeroAddress
    ==
    &&
    gtxn 0 RekeyTo
    global ZeroAddress
    ==
    &&
    gtxn 1 RekeyTo
    global ZeroAddress
    ==
    &&
    gtxn 0 Sender
    gtxn 0 Receiver
    !=
    &&
    gtxn 1 Sender
    gtxn 0 Receiver
    ==
    &&
    gtxn 2 Sender
    gtxn 0 Receiver
    ==
    &&
    gtxn 0 TypeEnum
    int pay
    ==
    &&
    gtxn 0 Amount
    int 1003000
    >=
    &&
    gtxn 1 TypeEnum
    int axfer
    ==
    &&
    gtxn 1 AssetAmount
    int 0
    ==
    &&
    gtxn 1 XferAsset
    int ${p}
    ==
    &&
    int ${s}
    int 0
    ==
    bnz main_l2
    gtxn 2 RekeyTo
    global ZeroAddress
    ==
    gtxn 2 TypeEnum
    int axfer
    ==
    &&
    gtxn 2 AssetAmount
    int 0
    ==
    &&
    gtxn 2 XferAsset
    int ${s}
    ==
    &&
    gtxn 3 TypeEnum
    int appl
    ==
    &&
    gtxn 3 Sender
    gtxn 0 Receiver
    ==
    &&
    gtxn 3 ApplicationID
    int ${AMM_ID}
    ==
    &&
    gtxn 3 OnCompletion
    int OptIn
    ==
    &&
    gtxna 3 ApplicationArgs 0
    int ${feeTier}
    itob
    ==
    &&
    gtxn 3 RekeyTo
    addr ${CENTRAL_ADDR}
    ==
    &&
    b main_l3
    main_l2:
    gtxn 2 TypeEnum
    int appl
    ==
    gtxn 2 ApplicationID
    int ${AMM_ID}
    ==
    &&
    gtxn 2 OnCompletion
    int OptIn
    ==
    &&
    gtxna 2 ApplicationArgs 0
    int ${feeTier}
    itob
    ==
    &&
    gtxn 2 RekeyTo
    addr ${CENTRAL_ADDR}
    ==
    &&
    main_l3:
    &&
    return`
  const sig = await algodClient.compile(LOGICSIG_TEMPLATE).do()
  const program = new Uint8Array(Buffer.from(sig.result, "base64"))
  return new LogicSigAccount(program)
}
