import { isValidAddress } from 'algosdk';
import { useEffect } from 'hoist-non-react-statics/node_modules/@types/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useAccountContext } from 'src/context/context';
import { shortenAddress } from 'src/Utils';
import { arrowDropdown } from '../Icons';
import './advanced.scss';

declare type DetailProps = {
  asa0?: string;
  asa1?: string;
  ratio: number;
  impact?: number;
  swapFee?: number;
  output: string;
  setOutput: (item: string) => void;
  poolPercent?: number;
  minLPreceived?: number;
  lpBurned?: number;
  poolFeeTier?: number,
  setPoolFeeTier?: (item: number) => void;
};

const AdvancedDetail = ({
  asa0,
  asa1,
  ratio,
  impact,
  swapFee,
  output,
  setOutput,
  poolPercent,
  minLPreceived,
  lpBurned,
  poolFeeTier,
  setPoolFeeTier
}: DetailProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [outputInternal, setOutputInternal] = useState<string>(output)
  const { addresses } = useAccountContext();

  const handleAddressSubmit = (address: string) => {
    if(isValidAddress(address)) {
      setIsClicked(false)
      setOutput(outputInternal)
    } else {
      toast.error("Invalid Address!")
    }
  }

  const calcImpactGoodness = (impact: number) => {
    if(impact < 0.5) return "excellent"
    if(impact < 1) return "good"
    if(impact < 5) return "medium"
    return "HIGH"
  }

  return (
    <div className="advanced-detail">
      {isOpen && (
        <div className="advanced-dropdown">
          {asa0 && asa1 && ratio ? (
            <p>
              1 {asa0} = ~{ratio.toFixed(4)} {asa1}
            </p>
          ) : ''}
          {impact ? <p>Impact: {impact.toFixed(3)}% ({calcImpactGoodness(impact)})</p> : ''}
          {swapFee ? <p>Swap Fee: {swapFee} {asa0}</p> : ''}
          {poolFeeTier && setPoolFeeTier &&
            <div>
              Pool Fee Rate:
              <select name="feetiers" id="feetiers" onChange={(e) => {setPoolFeeTier(+e.target.value); console.log(+e.target.value)}}>
                <option value={1}>0.3%</option>
                <option value={2}>1%</option>
                <option value={3}>0.5%</option>
                <option value={4}>0.1%</option>
                <option value={5}>0.05%</option>
                <option value={6}>0.01%</option>
              </select> 
            </div>
          }
          {minLPreceived ? <p>Minimum LP Tokens Received: {minLPreceived.toFixed(4)}</p> : ''}
          {addresses[0] !== '' ? (
            <div>
              Output:
              {isClicked ? (
                <input type="text" value={outputInternal} onChange={e => setOutputInternal(e.target.value)} onKeyUp={e => {if(e.key == "Enter") { handleAddressSubmit(outputInternal)}}}/>
              ) : (
                <span onClick={() => {setOutputInternal(output); setIsClicked(true)}}>
                  {shortenAddress(output)}
                </span>
              )}
            </div>
          ) : ''}
        </div>
      )}
      <div className="flex justify-start items-center">
        <button onClick={() => setIsOpen(!isOpen)}>
          Advanced Details
          <div className={`arrow ${isOpen ? 'toggle-up' : 'toggle-down'}`}>{arrowDropdown}</div>
        </button>
      </div>
    </div>
  );
};

export default AdvancedDetail;
