import React, { ReactElement, useContext } from 'react';
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './theme/ThemeProvider';
import Router from './routes';
import 'react-toastify/dist/ReactToastify.css';

const App = (): ReactElement => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={theme}>
      <Router />
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        icon={true}
        theme={'colored'}
        pauseOnHover={false}
        rtl={false}
        limit={1}
      />
    </div>
  );
};

export default App;
