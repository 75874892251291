import React, { useEffect, useState } from 'react';
type Theme = 'light' | 'dark';
type ThemeContext = { theme: Theme; toggleTheme: () => void };

export const ThemeContext = React.createContext<ThemeContext>(
  {} as ThemeContext,
);

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>('dark');
  const toggleTheme = () => {
    console.log(theme)
    setTheme(theme === 'light' ? 'dark' : 'light');
    window.localStorage.setItem('midafiTheme', theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    const localTheme: any = window?.localStorage?.getItem('midafiTheme');
    localTheme && setTheme(localTheme);
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
