import React, { useState, useRef, useEffect, useContext } from 'react';
import { percentageIcon } from 'src/components/Icons';
import { DEFAULT_IMG } from 'src/constants';
import { percentageIconDown } from '../../../components/Icons';
import { testNetToMainNetIcons } from '../../../constants';
import "./tokenstatus.scss"

interface tokenData {
  show: boolean;
  asa0Amount: number;
  asa1Amount: number;
  asa0: number;
  asa1: number;
  tvl: number;
  tvlPercent: number;
  volume: number;
  feePercent: number;
  fee: number;
  name1: string;
  name2: string;
  d1: number;
  d2: number;
}

const TokenStatus = ({ show, asa0, asa1, asa0Amount, asa1Amount, tvl, tvlPercent, volume, feePercent, fee, name1, name2, d1, d2 }: tokenData) => {
  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = DEFAULT_IMG;
    event.currentTarget.className = 'error';
  };
  
  return (
    <div className={`token-detail ${show && 'show'}`}>
      <div className="">
        <p>Total Tokens Locked</p>
        <div>
          <div>
            <img src={!isNaN(testNetToMainNetIcons[asa0 as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[asa0 as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} />
            {Number(asa0Amount / 10 ** d1).toFixed(2)} {`${name1}`}
          </div>
          <div>
            <img src={!isNaN(testNetToMainNetIcons[asa1 as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[asa1 as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} />
            {Number(asa1Amount / 10 ** d2).toFixed(2)} {`${name2}`}
          </div>
        </div>
      </div>
      <div>
        <p>TVL</p>
        <div>
          ${tvl}
          <div className={`percentage-${tvlPercent >= 0 ? "green" : "red"}`}>
            {tvlPercent >= 0 ? '+' : ''}{tvlPercent}%
            {tvlPercent >= 0 ? percentageIcon : percentageIconDown}
          </div>
        </div>
      </div>
      <div>
        <p>Volume 24h</p>
        <div>
          ${volume}
          {/* <div className="percentage">
            {feePercent > 0 ? '+' : ''}{feePercent}%
            {percentageIcon}
          </div> */}
        </div>
      </div>
      <div>
        <p>24h Fees</p>
        <div>
          ${fee}
        </div>
      </div>
    </div>
  )
}

export default TokenStatus