// this is a component file
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { PriceChart } from 'src/components/PriceChart';
import { calendarIcon, discord, FooterLogo, percentageIcon, reddit, twitter } from 'src/components/Icons';
import { ChartData } from 'src/types';
import { callAPI } from 'src/Utils';
import Accordion from './accordion';
import HeaderComponent from 'src/components/Header';
import './analytics.scss'

const defaultChartData: ChartData[] = [
  {
    time: 0,
    y_position: 0,
    price: 0,
  },
  {
    time: 10,
    y_position: 20,
    price: 0,
  },
  {
    time: 20,
    y_position: 50,
    price: 0,
  },
  {
    time: 30,
    y_position: 40,
    price: 0,
  },
  {
    time: 40,
    y_position: 0,
    price: 0,
  },
  {
    time: 50,
    y_position: 50,
    price: 0,
  },
];

const chartPeriod = [
  '1D', '7D', '1M', '1Y', 'All Time'
];

const topPool = [
  {
    firstType: 'algo',
    firstQuantity: 154.6,
    secondType: 'gomint',
    secondQuantity: '1.874K',
    feeTier: 0.01,
    tvl: '$2.8M',
    volume24: '$2.8M',
    volume7: '$2.8M'
  },
  {
    firstType: 'algo',
    firstQuantity: 154.6,
    secondType: 'gomint',
    secondQuantity: '1.874K',
    feeTier: 1,
    tvl: '$8.8M',
    volume24: '$8.8M',
    volume7: '$8.8M'
  },
  {
    firstType: 'algo',
    firstQuantity: 154.6,
    secondType: 'gomint',
    secondQuantity: '1.874K',
    feeTier: 0.2,
    tvl: '$1.3M',
    volume24: '$1.3M',
    volume7: '$1.3M'
  },
  {
    firstType: 'algo',
    firstQuantity: 154.6,
    secondType: 'gomint',
    secondQuantity: '1.874K',
    feeTier: 0.01,
    tvl: '$2.8M',
    volume24: '$2.8M',
    volume7: '$2.8M'
  },
  {
    firstType: 'algo',
    firstQuantity: 154.6,
    secondType: 'gomint',
    secondQuantity: '1.874K',
    feeTier: 1,
    tvl: '$8.8M',
    volume24: '$8.8M',
    volume7: '$8.8M'
  },
  {
    firstType: 'algo',
    firstQuantity: 154.6,
    secondType: 'gomint',
    secondQuantity: '1.874K',
    feeTier: 0.2,
    tvl: '$1.3M',
    volume24: '$1.3M',
    volume7: '$1.3M'
  }
]

const topAsa = [
  {
    name: 'ASA0',
    price: '$8.8M',
    change: 5,
    detail: [
      {
        firstType: 'algo',
        firstQuantity: 154.6,
        secondType: 'gomint',
        secondQuantity: '1.874K',
        feeTier: 0.01,
        tvl: '$2.8M',
        volume24: '$2.8M',
        volume7: '$2.8M'
      },
      {
        firstType: 'algo',
        firstQuantity: 154.6,
        secondType: 'gomint',
        secondQuantity: '1.874K',
        feeTier: 1,
        tvl: '$8.8M',
        volume24: '$8.8M',
        volume7: '$8.8M'
      },
      {
        firstType: 'algo',
        firstQuantity: 154.6,
        secondType: 'gomint',
        secondQuantity: '1.874K',
        feeTier: 0.2,
        tvl: '$1.3M',
        volume24: '$1.3M',
        volume7: '$1.3M'
      }
    ]
  },
  {
    name: 'ASA0',
    price: '-$545.8K',
    change: 5,
    detail: [
      {
        firstType: 'algo',
        firstQuantity: 154.6,
        secondType: 'gomint',
        secondQuantity: '1.874K',
        feeTier: 0.01,
        tvl: '$2.8M',
        volume24: '$2.8M',
        volume7: '$2.8M'
      },
      {
        firstType: 'algo',
        firstQuantity: 154.6,
        secondType: 'gomint',
        secondQuantity: '1.874K',
        feeTier: 1,
        tvl: '$8.8M',
        volume24: '$8.8M',
        volume7: '$8.8M'
      },
      {
        firstType: 'algo',
        firstQuantity: 154.6,
        secondType: 'gomint',
        secondQuantity: '1.874K',
        feeTier: 0.2,
        tvl: '$1.3M',
        volume24: '$1.3M',
        volume7: '$1.3M'
      }
    ]
  }
]

const Analytics = () => {

  const [isClicked, setIsClicked] = useState(1)
  const [chartData, setChartData] = useState<ChartData[]>(defaultChartData);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);


  const getChartData = async () => {
    try {
      let response;
      switch (isClicked) {
        case 0:
          response = await callAPI('algorand', 'usd', '1');
          break;
        case 1:
          response = await callAPI('algorand', 'usd', '1');
          break;
        case 2:
          response = await callAPI('algorand', 'usd', '7');
          break;
        case 3:
          response = await callAPI('algorand', 'usd', '30', 'daily');
          break;
        case 4:
          response = await callAPI('algorand', 'usd', '365', 'daily');
          break;
        default:
          response = await callAPI('algorand', 'usd', '1');
          break;
      }
      const chartTemp = [];
      const temp = [];
      for (let i = 0; i < response.data.prices.length; i++) {
        temp.push(response.data.prices[i][1]);
        chartTemp.push({
          time: response.data.prices[i][0],
          y_position: response.data.prices[i][1],
          price: response.data.prices[i][1]
        })
      }
      setMin(Math.min(...temp));
      setMax(Math.max(...temp));
      setChartData(chartTemp);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getChartData();
  }, [isClicked])

  return (
    <>
      <HeaderComponent />
      <div className='analytics-container'>
        <div className='flex items-center justify-between'>
          <div className='total-info'>
            <div>
              <p><span>Volume 24H</span> $ 1.80b</p>
              <div className="percentage">
                +26.51% {percentageIcon}
              </div>
            </div>
            <div>
              <p><span>Fees 24H</span> $ 2.19m</p>
              <div className="percentage">
                +24.65% {percentageIcon}
              </div>
            </div>
            <div>
              <p><span>TVL</span> $ 4.16b</p>
              <div className="percentage down">
                -1.86% {percentageIcon}
              </div>
            </div>
          </div>
          <div className='period-wrap flex items-center'>
            <div className="price-period">
              {chartPeriod.map((item, index) => (
                <div key={`period-${index}`} onClick={() => setIsClicked(index)} className={`${index === isClicked && 'active'}`}>{item}</div>
              ))}
            </div>
            {calendarIcon}
          </div>
        </div>
        <div className='chart-group'>
          <div className='chart1'>
            <h2>TVL</h2>
            <h1>$4.16b</h1>
            <PriceChart data={isClicked === 0 ? chartData.slice(-60) : chartData} min={min} max={max} />
          </div>
          <div className='chart2'>
            <h2>Volume 24 H</h2>
            <h1>$1.80b</h1>
            <PriceChart data={isClicked === 0 ? chartData.slice(-60) : chartData} min={min} max={max} />
          </div>
        </div>
        <div className='top-asa'>
          <h1>Top ASAs</h1>
          <div className='table-responsive'>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Change(24h)</th>
                </tr>
              </thead>
              <tbody>
                {topAsa.map((item, index) => (
                  <Accordion key={`top-asa-${index}`} item={item} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='top-asa top-pool'>
          <h1>Top Pools</h1>
          <div className='table-responsive'>
            <table>
              <thead>
                <tr>
                  <th>Pool</th>
                  <th>Feer Tier</th>
                  <th>TVL</th>
                  <th>Volume(24h)</th>
                  <th>Volume(7d)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {topPool.map((detail, index) => (
                  <tr key={`detail-${index}`} >
                    <td>
                      <div>
                        <img src={`images/${detail.firstType}.svg`} />
                        {detail.firstQuantity}{' '}
                        {detail.firstType}
                        <img src={`images/${detail.secondType}.svg`} />
                        {detail.secondQuantity}{' '}
                        {detail.secondType}
                      </div>
                    </td>
                    <td>{detail.feeTier}%</td>
                    <td>{detail.tvl}</td>
                    <td>{detail.volume24}</td>
                    <td>{detail.volume7}</td>
                    <td>
                      <img src="images/plus.png" alt="plus" className='btns' />
                      <img src="images/switch_2.png" alt="switch" className='btns' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='trade-footer'>
          <Link to="/">{FooterLogo}</Link>
          <div className='trade-footer-social'>
            <a href="#" target="_blank">{twitter}</a>
            <a href="#" target="_blank">{discord}</a>
            <a href="#" target="_blank">{reddit}</a>
            <Link to="/contact" className='footer-contact'>Contact us</Link>
          </div>
        </div>
      </div >
    </>
  )
}

export default Analytics
