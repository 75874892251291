import React from "react";
import "./txconfirm.scss";
import { ITxConfirm } from "src/types";
import { CloseIcon } from "../Icons";
import { shortenAddress } from "../../Utils";
import { testNetToMainNetIcons } from "../../constants";
const stateHeaders = {
  1: "Waiting for Signature...",
  2: "Waiting for Chain...",
  3: "Transaction Completed",
  4: "Transaction Completed",
  5: "Transaction Completed",
  6: "Signature Denied",
  7: "Transaction Failed"
}


//0 == not shown, 1 == waiting for signature, 2 == waiting for blockchain, 3 == swap successful, 4 == mint successful, 5 == burn successful, 6 == signature denied, 7 == other error
const TxConfirmModal = ({txns, confirmedState, setConfirmedState, setLastTx}: ITxConfirm) => {


  const handleClickOutside = (e: React.MouseEvent) => {
    if(e.target === document.getElementById("transaction-modal-background")){
      setConfirmedState(0);
      setLastTx(null);
    }
  }

  return (
    <div className="confirm-modal-wrap" id="transaction-modal-background" onClick={handleClickOutside}>
      <div className="confirm-modal">
        <div className="modal-header">
          <h2>{stateHeaders[confirmedState as keyof typeof stateHeaders]}</h2>
          <button onClick={() => {setConfirmedState(0); setLastTx(null)}}>
            {/* <img src="/images/exit.png" alt="exit" /> */}
            {CloseIcon}
          </button>
        </div>
        {[3, 4, 5].includes(confirmedState) && txns != null && <div className="items">
          <h3>Assets received</h3>
          <div>
            <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[txns[confirmedState == 4 ? 3 : 1]?.xaid as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
            {txns[confirmedState == 4 ? txns.length - 1 : 1].assetName}
          </div>
          <h1>{txns[confirmedState == 4 ? txns.length - 1 : 1].amount/(10**txns[confirmedState == 4 ? txns.length - 1 : 1].assetDecimals)}</h1>
        </div>}
        {confirmedState == 5 && txns != null && <div className="items">
          <h3/>
          <div>
            <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[txns[2]?.xaid as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
            {txns[2]?.assetName}
          </div>
          <h1>{txns[2].amount/(10**txns[2].assetDecimals)}</h1>
        </div>}
        {[3, 4, 5].includes(confirmedState) && txns != null && <div className="items">
          <h3>Assets sent</h3>
          <div>
            <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[txns[0]?.xaid as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
            {txns[0]?.assetName}
          </div>
          <h1>{txns[0].amount/(10**txns[0].assetDecimals)}</h1>
        </div>}
        {confirmedState == 4 && txns != null && <div className="items">
          <h3/>
          <div>
            <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[txns[1]?.xaid as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
            {txns[1]?.assetName}
          </div>
          <h1>{(txns[1].amount - (txns.length == 4 ? txns[2].amount : 0))/(10**txns[1].assetDecimals)}</h1>
        </div>}
        {txns != null && <div className="items">
          <h3>Transaction ID</h3>
          <a href={`https://testnet.algoexplorer.io/tx/${encodeURIComponent(txns[txns.length - 1].txid)}`} target='_blank' rel="noreferrer">
            {shortenAddress(txns[txns.length - 1].txid)}
          </a>
        </div>}
      </div>
    </div>
  )
}

export default TxConfirmModal