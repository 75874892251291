/* eslint-disable prefer-const */
// this is a component file
import React, { useState, useRef, useEffect, useContext } from 'react';
import { PriceChart } from 'src/components/PriceChart';
import { ChartData, IAssetData, IPoolInfo } from 'src/types';
import { useAccountContext } from 'src/context/context';
import { arrowDropdown, calendarIcon, CloseIcon, discord, FooterLogo, percentageIcon, reddit, searchIcon, twitter } from 'src/components/Icons';
import { callAPI, mint, SwapToken, burn, fetchGetApi, shortenAddress } from 'src/Utils';
import SwapForm from 'src/components/SwapForm';
import BalanceCard from 'src/components/BalanceCard';
import { SwitchIcon, PlusIcon } from 'src/components/Icons';
import AdvancedDetail from 'src/components/AdvancedDetail';
import TokenStatus from './TokenStatus';
import TradeType from 'src/components/TradeType';
import { Link } from 'react-router-dom';
import TxConfirmModal from 'src/components/TransactionConfirmModal';
import PoolModal from 'src/components/PoolCreateModal';
import HeaderComponent from 'src/components/Header';
import "./trade.scss";
import { AssetIDs, DEFAULT_IMG, MICROALGOS_TO_ALGOS_RATIO } from 'src/constants';
import { toast } from 'react-toastify';
import { TransactionData } from '../../types';
import { algodClient } from '../../Utils/connections';
import { Transaction, waitForConfirmation } from 'algosdk';
import { apiGetAccountAssets, createPool, generateLogicSig, optIn } from '../../Utils';
import { percentageIconDown } from '../../components/Icons';
import AnyTransaction from 'algosdk/dist/types/src/types/transactions';
import { testNetToMainNetIcons } from '../../constants';

const defaultChartData: ChartData[] = [
  {
    time: 0,
    y_position: 0,
    price: 0,
  },
];

const tradeTypeList = [
  'Swap', 'Mint', 'Burn'
];


const chartPeriod = [
  '1D', '7D', '1M', '1Y', 'All Time'
];

const list1 = [
  'algo', 'ASA1'
];

const list2 = [
  'usdc'
];

const tokenData = {
  algo: 154.6,
  gomint: 1.874,
  tvl: 288.54,
  tvlPercent: 0.08,
  volume: 4.86,
  feePercent: 9.28,
  fee: 4.45,
}

type tableDataType = {
  type: string;
  groupId: string;
  totalValue: number;
  tokenInAmount: string;
  tokenInType: number;
  tokenOutAmount: string;
  tokenOutType: number;
  time: string;
}

const tokenList = [
  {
    symbol: 'algo',
    name: 'Algorand',
  },
  {
    symbol: 'eth',
    name: 'Ether',
  },
  {
    symbol: '1inch',
    name: '1Inch',
  },
  {
    symbol: 'aave',
    name: 'Aave',
  },
  {
    symbol: 'amp',
    name: 'Amp',
  },
  {
    symbol: 'ant',
    name: 'Aragon Network Token',
  },
  {
    symbol: 'ape',
    name: 'ApeCoin',
  },
  {
    symbol: 'bal',
    name: 'Balancer',
  },
  {
    symbol: 'band',
    name: 'Band Protocol',
  },
  {
    symbol: 'bat',
    name: 'Basic Attention Token',
  },
  {
    symbol: 'bnt',
    name: 'Bancor Network Token',
  },
  {
    symbol: 'comp',
    name: 'Compound',
  },
]

const mainTokens = [
  {
    symbol: 'eth',
    image: '/images/eth1.png',
  },
  {
    symbol: 'dai',
    image: '/images/dai.png',
  },
  {
    symbol: 'usdc',
    image: '/images/usdc.png',
  },
  {
    symbol: 'usdt',
    image: '/images/usdt.png',
  },
  {
    symbol: 'wbtc',
    image: '/images/wbtc.png',
  },
  {
    symbol: 'weth',
    image: '/images/weth.png',
  },
]

const InitialPoolInfo = {
  addr: "FQYGYECNRASILFENQL3COZ7HFXGI7K74TALHWNA5GM3OMZM6M4RZFI3ZUU",
  p: 10458941,
  s: 0,
  lp: 97970732,
  f: 1,
  b0: 15123796,
  b1: 8008295,
  bl: 10999000,
  f0: 0,
  f1: 0,
  f2: 0
}

const feeTable = [
  0.997,
  0.99,
  0.995,
  0.999,
  0.9995,
  0.9999,
]

type IASAs = {
  name: string,
  url: string,
  amount: number,
  decimals: number,
  id: number,
  unit_name: string,
}

const InitialASAs = [
  {
    name: "USD Coin",
    url: "https://centre.io",
    amount: 18446744073709552000,
    decimals: 6,
    id: 10458941,
    unit_name: "USDC"
  },
  {
    name: "Algorand",
    url: "https://www.algorand.com/",
    amount: 10000000000000000,
    decimals: 6,
    id: 0,
    unit_name: "Algo"
  }
]

export let getAsaNameById: any;

const Trade = () => {
  const { addresses, slippage, walletType, assets, setAssets } = useAccountContext();
  const rightRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const [isHamburger, setIsHamburger] = useState<boolean>(false)
  const [isTokenModal, setIsTokenModal] = useState<boolean>(false)
  const [swapToken, setSwapToken] = useState(1)
  const [poolModal, setPoolModal] = useState<boolean>(false)
  const [poolInfo, setPoolInfo] = useState<IPoolInfo>(InitialPoolInfo)
  const [alert, setAlert] = useState<string>('')
  const [asas, setASAs] = useState<IASAs[]>(InitialASAs)
  const [mainAsas, setMainASAs] = useState<IASAs[]>(InitialASAs)
  const [popAsas, setPopASAs] = useState<IASAs[]>(InitialASAs)
  const [chartData, setChartData] = useState<ChartData[]>(defaultChartData);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [isAlert, setIsAlert] = useState(true);
  const [isClicked, setIsClicked] = useState(0);
  const [selected, setSelected] = useState(0);
  const [selected1, setSelected1] = useState(10458941);
  const [selected2, setSelected2] = useState(100636711);
  const [value1, setValue1] = useState<number | null>(null);
  const [value2, setValue2] = useState<number | null>(null);
  const [value3, setValue3] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [tradeType, setTradeType] = useState(tradeTypeList[0]);
  const [tableData, setTableData] = useState<tableDataType[]>([])
  const [output, setOutput] = useState<string>('')
  const [lastTx, setLastTx] = useState<Array<TransactionData> | null>(null)
  const [poolFeeTier, setPoolFeeTier] = useState<number>(1)
  const [lpOut, setLpOut] = useState<number>(0)
  const [asaFilter, setAsaFilter] = useState<string>('')
  const [lpTokens, setLpTokens] = useState<Array<number>>([])
  const [lastEdited, setLastEdited] = useState<number>(0)
  const [poolCreateToken1, setPoolCreateToken1] = useState<any>()
  const [poolCreateToken2, setPoolCreateToken2] = useState<any>()
  const [isWaitingForTx, setIsWaitingForTx] = useState<boolean>()
  const [searchedASAs, setSearchedASAs] = useState<any>([])
  const [fees24h, setFees24h] = useState<any>()
  const [volume24h, setVolume24h] = useState<any>()
  const [TVL, setTVL] = useState<any>()
  const [TVLPercent, setTVLPercent] = useState<any>()

  //0 == not shown, 1 == waiting for signature, 2 == waiting for blockchain, 3 == swap successful, 4 == mint successful, 5 == burn successful, 6 == signature denied, 7 == other error
  const [confirmedState, setConfirmedState] = useState<number>(0)

  const selectToken1 = () => {
    setIsTokenModal(true)
    setSwapToken(1)
  }

  const selectToken2 = () => {
    setIsTokenModal(true)
    setSwapToken(2)
  }

  const selectToken3 = () => {
    setIsTokenModal(true)
    setSwapToken(3)
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = DEFAULT_IMG;
    event.currentTarget.className = 'error';
  };

  getAsaNameById = (id: number) => {
    return (asas?.find(x => x.id === id)?.unit_name as string) ?? ''
  }

  const getDecimalsById = (id: number) => {
    return asas?.find(x => x.id === id)?.decimals as number
  }

  const formatTime = (timeStr: string) => {
    const date = new Date(Number(timeStr) * 1000)
    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthArray[date.getMonth()]
    const day = date.getDate()
    const hours = date.getHours() % 12 ? date.getHours() % 12 : 12
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

    return `${month} ${day}, ${hours}:${String(minutes).padStart(2, '0')} ${ampm}`
  }

  const getTransaction = async () => {
    console.log(tableData, 'tableData')
    let response: any
    // const token1 = tradeType == 'Burn' ? selected2 : selected
    console.log('Transaction: ')
    if (selected < selected1) {
      response = await fetchGetApi(`getTrans?p=${selected1}&s=${selected}&block=0`)
    } else {
      response = await fetchGetApi(`getTrans?p=${selected}&s=${selected1}&block=0`)
    }
    console.log(response)
    const tableArray: tableDataType[] = []
    for (let i = 0; i < response.length; i++) {
      let temp: tableDataType
      switch (response[i].action) {
        case 'swap':
          temp = {
            type: `Swap ${response[i].pIn === 0 ? getAsaNameById(response[i].s).toUpperCase() : getAsaNameById(response[i].p).toUpperCase()} for ${response[i].pIn === 0 ? getAsaNameById(response[i].p).toUpperCase() : getAsaNameById(response[i].s).toUpperCase()}`,
            groupId: response[i].group,
            totalValue: response[i].value,
            tokenInAmount: `${response[i].pIn === 0 ? response[i].sIn / 10 ** getDecimalsById(response[i].s) : response[i].pIn / 10 ** getDecimalsById(response[i].p)}`,
            tokenInType: response[i].pIn === 0 ? response[i].s : response[i].p,
            tokenOutAmount: `${response[i].pIn === 0 ? response[i].pOut / 10 ** getDecimalsById(response[i].p) : response[i].sOut / 10 ** getDecimalsById(response[i].s)}`,
            tokenOutType: response[i].pIn === 0 ? response[i].p : response[i].s,
            time: formatTime(response[i].ts),
          }
          tableArray.push(temp)
          break;

        case 'mint':
          temp = {
            type: `Add ${getAsaNameById(response[i].p).toUpperCase()} and ${getAsaNameById(response[i].s).toUpperCase()}`,
            groupId: response[i].group,
            totalValue: response[i].value,
            tokenInAmount: `${response[i].pIn / 10 ** getDecimalsById(response[i].p)}`,
            tokenInType: response[i].p,
            tokenOutAmount: `${response[i].sIn / 10 ** getDecimalsById(response[i].s)}`,
            tokenOutType: response[i].s,
            time: formatTime(response[i].ts),
          }
          tableArray.push(temp)
          break;

        case 'burn':
          temp = {
            type: `Remove ${getAsaNameById(response[i].p).toUpperCase()} and ${getAsaNameById(response[i].s).toUpperCase()}`,
            groupId: response[i].group,
            totalValue: response[i].value,
            tokenInAmount: `${response[i].pOut / 10 ** getDecimalsById(response[i].p)}`,
            tokenInType: response[i].p,
            tokenOutAmount: `${response[i].sOut / 10 ** getDecimalsById(response[i].s)}`,
            tokenOutType: response[i].s,
            time: formatTime(response[i].ts),
          }
          tableArray.push(temp)
          break;

        default:
          break;
      }
    }
    setTableData(tableArray)
  }



  const getPoolInfo = async () => {
    try {
      let response: any
      let temp: number
      // If burn, selected will be the LP token, which isn't what we're looking for
      // temp = (tradeType == 'Burn' ? selected2 : selected)
      console.log("Pool selected:",selected)
      if (selected < selected1) {
        if(tradeType == "Burn") {
          response = await fetchGetApi(`getPoolByLP?lp=${selected2}`)
        } else {
          console.log(`getPool${tradeType == "Swap" ? 's' : ''}?p=${selected1}&s=${selected}${tradeType != "Swap" ? '&f='+ poolFeeTier : ''}`)
          response = await fetchGetApi(`getPool${tradeType == "Swap" ? 's' : ''}?p=${selected1}&s=${selected}${tradeType != "Swap" ? '&f='+ poolFeeTier : ''}`)
        }

        if(tradeType != "Swap") response = [response];
        if(response[0] != null) {
          const outputs = [];
          for (let index = 0; index < response.length; index++) {
            const pool = response[index];
            const output = pool?.b0 - pool?.b0 * pool?.b1 / (pool?.b1 + Number(value1 ?? 1) * feeTable[pool?.f - 1] * 10 ** getDecimalsById(selected))
            outputs.push(output)
          }
          const max = Math.max(...outputs);
          const index = outputs.indexOf(max) == -1 ? 0 : outputs.indexOf(max)
          console.log(index)
          setPoolInfo(response[index])
        } else {
          setPoolInfo(response[0])
        }
        
      } else {
        if(tradeType == "Burn") {
          response = await fetchGetApi(`getPoolByLP?lp=${selected2}`)
        } else {
          response = await fetchGetApi(`getPool${tradeType == "Swap" ? 's' : ''}?p=${selected}&s=${selected1}${tradeType != "Swap" ? '&f='+ poolFeeTier : ''}`)
          console.log(`getPool${tradeType == "Swap" ? 's' : ''}?p=${selected}&s=${selected1}${tradeType != "Swap" ? '&f='+ poolFeeTier : ''}`)
        }
        if(tradeType != "Swap") response = [response];
        if(response[0] != null) {
          const outputs = [];
          for (let index = 0; index < response.length; index++) {
            const pool = response[index];
            const output = pool?.b1 - pool?.b0 * pool?.b1 / (pool?.b0 + Number(value1 ?? 1) * feeTable[pool?.f - 1] * 10 ** getDecimalsById(selected))
            outputs.push(output)
          }
          const max = Math.max(...outputs);
          const index = outputs.indexOf(max) == -1 ? 0 : outputs.indexOf(max)
          console.log(index)
          setPoolInfo(response[index])
        } else {
          setPoolInfo(response[0])
        }
        
      }

      if(tradeType == "Mint") {
        console.log("Pool Info: " + response)
        console.log(selected + ":" + selected1)
        if(poolInfo) {
          console.log(JSON.stringify(response))
        }
        if(response == null || response.length == 0) {
          toast.error("Pool does not exist")
        } else if(response[0]?.bl == 0) {
          toast.error("Pool has not been initiated. Please input the preferred ratio.")
        }
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const getAlert = async () => {
    try {
      const response = await fetchGetApi(`settings`)
      if (response !== null)
        setAlert(response.alert)
    } catch (err: any) {
      console.log(err)
    }
  }

  const getASAs = async () => {
    try {
      const response = await fetchGetApi(`getASAs`)
      if (response !== null)
        setASAs(response)
      setPopASAs(await fetchGetApi(`getPopASAs`))
      setMainASAs(await fetchGetApi(`getTopASAs`))
      setLpTokens(await fetchGetApi(`getLPs`));
      // console.log([...popAsas, ...assets ?? []])
      // setSearchASAs([...popAsas, ...assets ?? []].filter((asa: any) => filterASAs(asa, asaFilter)).slice(0, 6))
    } catch (err: any) {
      console.log(err);
    }
  }
  const searchAsas = async (value: string) => {
    let response = [...assets ?? [], ...popAsas].filter((asset: any) => filterASAs(asset, value))
    // Remove duplicates
    response = response.filter((asset: any, index: number) => {return index == response.findIndex((otherAsset: any) => {return otherAsset.id == asset.id})})
    console.log(value, response)
    if(response.length > 0) {
      const firstSix = response.slice(0, 6)
      setSearchedASAs(firstSix)
    } else if(Number(value)) {
      try {
        const res = await algodClient.getAssetByID(Number(value)).do()

        const token =  {
          id: res.index,
          amount: res.params.total,
          creator: res.params.creator,
          frozen: res.params['default-frozen'],
          decimals: res.params.decimals,
          name: res.params.name,
          unit_name: res.params['unit-name'],
          url: res.params.url,
        }
        setSearchedASAs([token])

      } catch {
        setSearchedASAs([])
      }
    } 
    return;
  }

  const handleClickOutside = (event: any) => {
    if (rightRef.current && !rightRef.current.contains(event.target)) {
      if (isHamburger) setIsHamburger(false)
    }
  }

  const handleClickOutsideModal = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      if (isTokenModal) setIsTokenModal(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideModal, false)

    return () => {
      document.removeEventListener('click', handleClickOutsideModal, false)
    }
  })

  useEffect(() => {
    setOutput(addresses[0])
  }, [addresses])

  const handleSwapSwitch = async () => {

    if (tradeType === 'Swap') {
      

      if(lastEdited == 1) {
        let temp: any = value1
        // console.log(calculateSwapFromOutput(value2, selected1, selected))
        setValue1((calculateSwapFromOutput(temp, selected1, selected) ?? 0) / (10 ** getDecimalsById(selected1)))
        setValue2(value1)
        temp = selected1
        setSelected1(selected)
        setSelected(temp)
        setLastEdited(2)
      } else {
        let temp: any = value2
        
        setValue1(value2)
        setValue2((calculateSwap(temp, selected1, selected) ?? 0) / (10 ** getDecimalsById(selected)))
        temp = selected
        setSelected(selected1)
        setSelected1(temp)
        setLastEdited(1)
      }

      /*
      if(lastEdited == 1) {
        let temp: any = value1
        setValue1((calculateSwapFromOutput(temp, selected, selected1) ?? 0) / (10 ** getDecimalsById(selected1)))
        setValue2(temp)
        temp = selected
        setSelected(selected1)
        setSelected1(temp)
        setLastEdited(2)
      } else {
        let temp: any = value1
        setValue1(value2)
        console.log(calculateSwap(value2, selected1, selected))
        setValue2((calculateSwap(value2, selected1, selected) ?? 0) / (10 ** getDecimalsById(selected1)))
        temp = selected
        setSelected(selected1)
        setSelected1(temp)
        setLastEdited(1)
      }
      */

      
      
    }
  }

  const selectSwapToken = async (item: number) => {
    let temp: number
    switch (swapToken) {
      case 1:
        setIsTokenModal(false)
        if(tradeType == 'Burn') {
          setSelected(item)
          let pool = await fetchGetApi(`getPoolByLP?lp=${item}`)
          console.log("Pool: " + pool.p + ":" + pool.s)
          if(pool) {
            setSelected1(pool.p)
            setSelected2(pool.s)
            setPoolFeeTier(pool.f)
            getPoolInfo()
          }
        } else {
          temp = selected
          setSelected(item)
          if (selected1 === item) {
            setSelected1(temp)
          }
        }
        
        break;

      case 2:
        setIsTokenModal(false)
        temp = selected1
        setSelected1(item)
        if (selected === item) {
          setSelected(temp)
        }
        break;

      case 3:
        setIsTokenModal(false)
        temp = selected1
        setSelected2(item)
        if (selected1 === item) {
          setSelected(temp)
        }
        break;

      default:
        break;
    }
    inputValue1(0)
    setIsHamburger(true)
  }

  const getChartData = async () => {
    try {
      let response;
      switch (isClicked) {
        case 0:
          //response = await callAPI('algorand', 'usd', '1');
          response = await fetchGetApi("getPoolChart?d=1&p=10458941&s=0")
          break;
        case 1:
          response = await fetchGetApi("getPoolChart?d=7&p=10458941&s=0")
          break;
        case 2:
          response = await fetchGetApi("getPoolChart?d=30&p=10458941&s=0")
          break;
        case 3:
          response = await fetchGetApi("getPoolChart?d=365&p=10458941&s=0")
          break;
        case 4:
          response = await fetchGetApi("getPoolChart?d=3650&p=10458941&s=0")
          break;
        default:
          response = await fetchGetApi("getPoolChart?d=1&p=10458941&s=0")
          break;
      }
      let asa0: number, asa1: number;
      asa0 = selected > selected1 ? selected : selected1
      asa1 = selected > selected1 ? selected1 : selected
      const poolData = await fetchGetApi(`getPoolData?p=${asa0}&s=${asa1}`)
      console.log(poolData)
      setFees24h(+poolData.f.toFixed(2))
      setVolume24h(poolData.v)
      console.log(response[0])
      setTVL(response[response.length - 1].tvl.toFixed(2))
      setTVLPercent(((((response[response.length - 1].tvl - response[0].tvl)/response[response.length - 1].tvl)) * 100).toFixed(1))
      const chartTemp: { time: any; y_position: any; price: any; }[] = [];
      const temp: any[] = [];
      for (let i = 0; i < response.length; i++) {
        //temp.push(response.data.prices[i][1]);
        temp.push(response[i].ts);
        
        chartTemp.push({
          time: response[i].ts,
          //time: new Date(Date.parse(response[i].ts)).toISOString(),
          y_position: selected > selected1 ? response[i].r : 1/response[i].r,
          price: selected > selected1 ? response[i].r : 1/response[i].r
          /*
          time: response.data.prices[i][0],
          y_position: response.data.prices[i][1],
          price: response.data.prices[i][1]
          */
        })
      }
      setMin(Math.min(...temp));
      setMax(Math.max(...temp));
      setChartData(chartTemp);
    } catch (err) {
      console.log(err);
    }
  }

  const calculateSwap = (item: any, asset: number, asset1: number) => {
    try {
      if (asset > asset1) {
        // console.log('asset>asset1', asset, asset1, poolInfo, feeTable[poolInfo?.f - 1], getDecimalsById(asset), Number(item) * feeTable[poolInfo?.f - 1] * 10 ** getDecimalsById(asset))
        // return 17452648314 - 1926213 * 17452648314 / (1926213 + Number(item) * 10 ** getDecimalsById(asset))
        return Math.floor(poolInfo?.b1 - poolInfo?.b0 * poolInfo?.b1 / (poolInfo?.b0 + Number(item) * feeTable[poolInfo?.f - 1] * 10 ** getDecimalsById(asset)))
        // return 10103338 - 10103338 * 2773404 / (2773404 + Number(item) * 10 ** getDecimalsById(asset))
      } else {
        // return 1926213 * 17452648314 / (17452648314 - Number(item) * 10 ** getDecimalsById(asset)) - 1926213
        //return poolInfo?.b0 * poolInfo?.b1 / (poolInfo?.b1 - Number(item) * 10 ** getDecimalsById(asset)) - poolInfo?.b0
        return Math.floor(poolInfo?.b0 - poolInfo?.b0 * poolInfo?.b1 / (poolInfo?.b1 + Number(item) * feeTable[poolInfo?.f - 1] * 10 ** getDecimalsById(asset)))
        // return 10103338 * 2773404 / (10103338 - Number(item) * 10 ** getDecimalsById(asset)) - 2773404
        // console.log('asset<asset1', asset, asset1, poolInfo, feeTable[poolInfo?.f - 1], getDecimalsById(asset), Number(item) * feeTable[poolInfo?.f - 1] * 10 ** getDecimalsById(asset))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const calculateSwapFromOutput = (out: number, assetIn: number, assetOut: number) => {
    
    if(assetIn > assetOut) {
      
      return ((poolInfo?.b1 * poolInfo?.b0)/(poolInfo?.b1 - out  * 10 ** getDecimalsById(selected1))-poolInfo?.b0) * (2 - feeTable[poolInfo?.f - 1])
      //return (out * poolInfo?.b0)/(feeTable[poolInfo?.f - 1] * (poolInfo?.b1 - out)) * 10 ** getDecimalsById(assetIn)
    } else {
      //return 5000000;
      return ((poolInfo?.b1 * poolInfo?.b0)/(poolInfo?.b0 - out  * 10 ** getDecimalsById(selected1))-poolInfo?.b1) * (2 - feeTable[poolInfo?.f - 1])
      //return (out * poolInfo?.b1)/(feeTable[poolInfo?.f - 1] *  (poolInfo?.b0 - out)) * 10 ** getDecimalsById(assetIn);
    }
    
  }


  const calculateBurn = (item: any, asset: number) => {
    try {
      if (asset === poolInfo?.p) {
        return Math.floor(Number(item) * 10 ** getDecimalsById(asset) * poolInfo?.b0 / poolInfo?.bl)
      } else {
        return Math.floor(Number(item) * 10 ** getDecimalsById(asset) * poolInfo?.b1 / poolInfo?.bl)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const calcBurnFromOutput = (item: any, asset: number) => {
    //amtReq/(b0/bl)
    try {
      if (asset === poolInfo?.p) {
        return Math.floor(Number(item) * 10 ** getDecimalsById(asset) / (poolInfo?.b0 / poolInfo?.bl))
      } else {
        return Math.floor(Number(item) * 10 ** getDecimalsById(asset) / (poolInfo?.b1 / poolInfo?.bl))
      }
    } catch (error) {
      console.log(error)
    }
  }



  // const calculateMint = (pIn: number | null, sIn: number | null, p: number, s: number) => {
  //     let lpOut = -1;
  //     if(poolInfo?.bl == 0) {
  //       lpOut = Math.sqrt((pIn ?? 0) * (sIn ?? 0)) - 1000
  //     } else if(poolInfo == null) {
  //       return 0
  //     } else {
  //       lpOut = Math.min((pIn ?? 0) * poolInfo?.b1/poolInfo?.b0, (sIn ?? 0) * poolInfo?.bl/poolInfo?.b1)
  //     }
  //     console.log(lpOut)
  //     return lpOut
  // }


  const calculateLPOut = (pIn: number | null, sIn: number | null, p: number, s: number) => {
      let lpOut = -1;
      console.log("bl:", poolInfo?.bl)
      if(poolInfo?.bl == 0) {
        lpOut = Math.sqrt((Number(pIn) * 10 ** getDecimalsById(p) ?? 0) * (Number(sIn) * 10 ** getDecimalsById(s) ?? 0)) - 1000
      } else if(poolInfo == null) {
        console.log("Pool null?")
        return 0
      } else {
        if (poolInfo?.p == p) {
          lpOut = Math.min((pIn ?? 0) * 10 ** getDecimalsById(p) * poolInfo?.b1/poolInfo?.b0, (sIn ?? 0) * 10 ** getDecimalsById(s) * poolInfo?.bl/poolInfo?.b1) // Round off the input to not carry extra decimals
        } else {
          lpOut = Math.min((sIn ?? 0) * 10 ** getDecimalsById(s) * poolInfo?.b1/poolInfo?.b0, (pIn ?? 0) * 10 ** getDecimalsById(p) * poolInfo?.bl/poolInfo?.b1)
        }
      }
      console.log(lpOut)
      return lpOut
  }

  const calcMint = (item: any, asset: number) => {
    let v2 = -1;
    if (asset === poolInfo?.p) {
      v2 = (Number(item) * 10 ** getDecimalsById(asset) * poolInfo?.bl / poolInfo?.b1) * poolInfo?.b0 / poolInfo?.bl
    } else {
      v2 = (Number(item) * 10 ** getDecimalsById(asset) * poolInfo?.bl / poolInfo?.b0) * poolInfo?.b1 / poolInfo?.bl
    }
    console.log(v2)
    return v2
  }

  const inputValue1 = (item: any) => {
    setValue1(item)
    let item2: any, item3: any, lp: number
    switch (tradeType) {
      case 'Swap':
        
        item2 = calculateSwap(item, selected, selected1)
        //setValue2(item2 / 10 ** getDecimalsById(selected))
        setValue2(item2 / 10 ** getDecimalsById(selected1))
        setLastEdited(1)
        break;

      case 'Burn':
        item2 = calculateBurn(item, selected1)
        item3 = calculateBurn(item, selected2)
        console.log(item2, item3)
        setValue2(item2 / 10 ** getDecimalsById(selected1))
        setValue3(item3 / 10 ** getDecimalsById(selected2))
        break;

      case 'Mint':
        if(poolInfo?.bl != 0) {
          item2 = calcMint(item, selected1)
          setValue2(item2 / 10 ** getDecimalsById(selected1))
          lp = calculateLPOut(item, item2, selected, selected1)
        } else {
          lp = calculateLPOut(item, value2, selected, selected1)
        }
        
        setLpOut(lp / 10 ** 6)
        

        break;

      default:
        break;
    }
  }

  const inputValue2 = (item: any) => {
    setValue2(item)
    let lp: any, item1: any;
    switch(tradeType) {
      case 'Swap':
        item1 = calculateSwapFromOutput(item, selected1, selected)
        //setValue1(item1 / 10 ** getDecimalsById(selected1))
        setValue1(item1 / 10 ** getDecimalsById(selected))
        setLastEdited(2)
        break;

      case 'Mint':

        
        if(poolInfo?.bl != 0) {
          item1 = calcMint(item, selected)
          setValue1(item1 / 10 ** getDecimalsById(selected))
          lp = calculateLPOut(item1, item, selected, selected1)
        } else {
          lp = calculateLPOut(value1, item, selected, selected1)
        }
        
        setLpOut(lp / 10 ** 6)
        break;

      case 'Burn':

        lp = calcBurnFromOutput(item, selected1)
        setValue1(lp / 10 ** 6)
        item1 = calculateBurn(lp, selected2)
        setValue3(item1 / 10 * getDecimalsById(selected2) )

        break;
    }
    
    
  }

  const inputValue3 = (item: any) => {
    setValue3(item)
    let item2: any, item1: any, lp: number
    switch (tradeType) {

      case 'Burn':
        item2 = calculateBurn(item, selected1)
        item1 = calculateBurn(item, selected)
        console.log(item2, item1)
        setValue2(item2 / 10 ** getDecimalsById(selected1))
        setValue1(item1 / 10 ** getDecimalsById(selected))
        break;

      default:
        break;
    }
  }

  const asasToMicroAsas = (asset: number, algos: number | null) => {
    const microalgos = Number(algos) * 10 ** getDecimalsById(asset)
    return microalgos
  }
/*
const main = async () => {
    // Read file for Teal code - int 0
    const filePath = path.join(__dirname, 'sample.teal');
    const data = fs.readFileSync(filePath);

    // Compile teal
    const results = await algodClient.compile(data).do();
    return results;
};

main().then((results) => {
    // Print results
    console.log("Hash = " + results.hash);
    console.log("Result = " + results.result);
}).catch(e => {
    const error = e.body && e.body.message ? e.body.message : e;
    console.log(error);
});
*/
  const handleSubmit = async () => {
    console.log(assets)

    if(isWaitingForTx) {
      return;
    }
    if(assets && (!(assets.find(asset => asset.id === selected)) || !(assets.find(asset => asset.id === selected1)) || (tradeType == 'Mint' && poolInfo && !(assets.find(asset => asset.id === poolInfo.lp))))) {
      let optInAssets: number[] = [];
      if(!(assets.find(asset => asset.id === selected))) {
        optInAssets.push(selected)
      }
      if(!(assets.find(asset => asset.id === selected1))) {
        optInAssets.push(selected1)
      }
      if(tradeType == 'Mint' && poolInfo && !(assets.find(asset => asset.id === poolInfo.lp))) {
        optInAssets.push(poolInfo.lp)
      }

      setIsWaitingForTx(true);
      await optIn(optInAssets, addresses[0], walletType)
      setAssets(await apiGetAccountAssets(addresses[0]))
      setIsWaitingForTx(false);
      return;
    }
    if((value1 == null || value2 == null || value1 == 0 || value2 == 0) && !(tradeType == 'Mint' && poolInfo == null)) { 
      toast.error('Input amount!')
      return
    }

    let txs = Array<TransactionData>()
    if(!(tradeType == 'Mint' && poolInfo == null)) {
      setConfirmedState(1);
    }
    let response;
    switch (tradeType) {
      case 'Swap':
        // eslint-disable-next-line no-case-declarations
        response = await SwapToken(asasToMicroAsas(selected, value1), asasToMicroAsas(selected1, value2), selected, selected1, addresses[0], walletType, slippage, poolInfo, output)
        break;

      case 'Mint':
        if(poolInfo == null) {
          response = await fetchGetApi(`getASA?id=${selected}`)
          const response2 = await fetchGetApi(`getASA?id=${selected1}`)
          console.log(response)
          setPoolCreateToken1(response[0])
          setPoolCreateToken2(response2[0])
          setPoolModal(true);
          setConfirmedState(0)
          return;
        } else {
          response = await mint(asasToMicroAsas(selected, value1), asasToMicroAsas(selected1, value2), selected, selected1, addresses[0], walletType, poolFeeTier, lpOut)
        }
        
        break;

      case 'Burn':
        response =  await burn(asasToMicroAsas(selected2, value3), selected, selected1, addresses[0], walletType, poolFeeTier, asasToMicroAsas(selected, Number(value1) * (100 - slippage) / 100), asasToMicroAsas(selected1, Number(value2) * (100 - slippage) / 100))
        break;

      default:
        break;
    }

     if (response && Array.isArray(response)) {
          
      setConfirmedState(2)
      try {
        console.log("Txs", response)
        for(const tx of response) {
          let txResult = await waitForConfirmation(algodClient, tx, 4)
          console.log(txResult)
          if(txResult['inner-txns']) {
            for(let innerTx of txResult['inner-txns']) {
              let transfer = innerTx['txn']
              let assetInfo = await fetchGetApi(`getASA?id=${transfer['txn']['xaid'] ?? 0}`)
  
              txs.push({
                txid: tx,
                txResult: txResult.txn,
                xaid: transfer['txn']['xaid'] ? transfer['txn']['xaid'] : 0,
                amount: transfer['txn']['aamt'] ? transfer['txn']['aamt'] : transfer['txn']['amt'],
                assetName: transfer['txn']['xaid'] ? assetInfo[0]["unit_name"] : "ALGO",
                assetDecimals: assetInfo[0]["decimals"]
              });
            }
          }
          else {
            let transfer = txResult['inner-txns'] ? txResult['inner-txns'][0]['txn'] : txResult['txn']
          
            let assetInfo = await fetchGetApi(`getASA?id=${transfer['txn']['xaid'] ?? 0}`)
  
            txs.push({
              txid: tx,
              txResult: txResult.txn,
              xaid: transfer['txn']['xaid'] ? transfer['txn']['xaid'] : 0,
              amount: transfer['txn']['aamt'] ? transfer['txn']['aamt'] : transfer['txn']['amt'],
              assetName: transfer['txn']['xaid'] ? assetInfo[0]["unit_name"] : "ALGO",
              assetDecimals: assetInfo[0]["decimals"]
            });
          }
         
        }        
      } catch (error) {
        setConfirmedState(7)
      }
      setLastTx(txs)
      setAssets(await apiGetAccountAssets(addresses[0]))
      switch(tradeType) {
        case 'Swap':
          setConfirmedState(3)
          break;
        
        case 'Mint':
          setConfirmedState(4)
          break;
        
        case 'Burn':
          setConfirmedState(5)
          break;

      }
      
    } else if(response == -1) {
      setConfirmedState(7);
    } else {
      setConfirmedState(6)
    }
    getPoolInfo()
  }

  const filterASAs = (asa: any, asaFilter: string) => {
    if(!asa.unit_name.toLowerCase().includes(asaFilter.toLowerCase()) && !asa.id.toString().includes(asaFilter)) {
      return false
    }
    if(tradeType == 'Burn' && !(lpTokens.includes(asa.id))) {
      return false
    }
    return true;
    
  }

  const handleCreatePool = async () => {
    if(poolCreateToken1 && poolCreateToken2) {
      await createPool(poolCreateToken1.id, poolCreateToken2.id, addresses[0], walletType, poolFeeTier);
      setSelected(poolCreateToken1.id)
      setSelected1(poolCreateToken2.id)
      setTradeType("Mint")
      setPoolModal(false)
    } else {
      toast.error("Select a token!")
    }
  }

  const calcImpact = (asa0: number, asa1: number, amountIn: number) => {
    // amount out / (input * ratio)

    if(asa0 > asa1) {
      return (1 - (calculateSwap(amountIn, asa0, asa1) ?? 0) / (amountIn * 10 ** getDecimalsById(asa0)  * poolInfo?.b1/poolInfo?.b0)) * 100
    } else {
      return  (1 - (calculateSwap(amountIn, asa0, asa1) ?? 0) / (amountIn * 10 ** getDecimalsById(asa0) * poolInfo?.b0/poolInfo?.b1)) * 100
    }
  }

  // useEffect(() => {
  //   if (value1 !== 0 && value1 !== null && value2 !== 0 && value2 !== null) {
  //     setValue1(Number(Number(value1).toFixed(4)))
  //     setValue2(Number(Number(value2).toFixed(4)))
  //   }
  // }, [value1, value2])

  useEffect(() => {
    getAlert()
    getASAs()
    getPoolInfo()
    getTransaction()
    getChartData()
  }, [])

  useEffect(() => {
    getChartData()
  }, [isClicked])

  useEffect(() => {
    searchAsas(asaFilter)
  }, [asaFilter])

  useEffect(() => {
    getPoolInfo()
    getTransaction()
    getChartData()
  }, [selected, selected1, selected2, poolFeeTier, lastTx, tradeType])

  return (
    <>
      <HeaderComponent />
      <div className="trade-container">
        <button
          type="button"
          onClick={() => setIsHamburger(!isHamburger)}
          className={`hamburger ${isHamburger && 'active'}`}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="left-side">
          {(alert !== '' && isAlert) && <div className="alert alert-success" role="alert">
            <p>{alert}</p>
            <button className="close-icon" onClick={() => setIsAlert(false)}>
              {CloseIcon}
            </button>
          </div>}
          <div className="chart">
            <div className="coin-category">
              <div className="coin-tag">
                <h4>${chartData[chartData.length - 1]?.price.toFixed(2)}</h4>
                <h6>{asas.find(x => x.id === selected)?.unit_name}/{asas.find(x => x.id === selected1)?.unit_name}</h6>
                <button onClick={handleSwapSwitch}>
                  <img src="images/switch.png" alt="switch" />
                </button>
                <div className={`percentage-${ (chartData[chartData.length - 1]?.price - chartData[0]?.price) > 0 ? 'green' : 'red'}`}>
                  {(chartData[chartData.length - 1]?.price - chartData[0]?.price) > 0 ? '+' : ''}{(chartData[chartData.length - 1]?.price - chartData[0]?.price).toFixed(2)} ({((((chartData[chartData.length - 1]?.price - chartData[0]?.price)/chartData[chartData.length - 1]?.price)) * 100).toFixed(1)}%) {(chartData[chartData.length - 1]?.price - chartData[0]?.price) > 0 ? percentageIcon : percentageIconDown}
                </div>
                <button className={isOpen ? 'toggle-up' : 'toggle-down'} onClick={() => setIsOpen(!isOpen)}>
                  {arrowDropdown}
                </button>
              </div>
              <div className="flex justify-center items-center">
                <div className="price-period">
                  {chartPeriod.map((item, index) => (
                    <div key={`period-${index}`} onClick={() => setIsClicked(index)} className={`${index === isClicked && 'active'}`}>{item}</div>
                  ))}
                </div>
                {/* <div className='calendarIcon'>
                  {calendarIcon}
                </div> */}
              </div>
            </div>
            {isOpen && <TokenStatus
              show={isOpen}
              asa0Amount={selected > selected1 ? poolInfo?.b0 : poolInfo?.b1}
              asa1Amount={selected > selected1 ? poolInfo?.b1 : poolInfo?.b0}
              asa0={selected}
              asa1={selected1}
              tvl={TVL}
              tvlPercent={TVLPercent}
              volume={volume24h.toFixed(2)}
              feePercent={tokenData.feePercent}
              fee={fees24h}
              name1={getAsaNameById(selected).toUpperCase()}
              name2={getAsaNameById(selected1).toUpperCase()}
              d1={getDecimalsById(selected)}
              d2={getDecimalsById(selected1)}
            />}
            <PriceChart data={isClicked === 0 ? chartData.slice(-60) : chartData} min={min} max={max} />
          </div>
          <div className={`table-responsive ${(!isAlert || alert === '') && 'height-alert'}`}>
            {tableData.length === 0 && <div className='loading-box'>
              <div className="loading" />
              <h3>Loading...</h3>
            </div>}
            <table>
              <thead>
                <tr>
                  <th>Transaction Type</th>
                  <th>Total Value</th>
                  <th>Token Amount</th>
                  <th>Token Amount</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item: tableDataType, index: number) => (
                  <tr key={`table-data-${index}`} className="transaction">
                    <td>{<a href={`https://testnet.algoexplorer.io/tx/group/${encodeURIComponent(item.groupId)}`}>{item.type}</a>}</td>
                    <td>${item.totalValue.toFixed(2)}</td>
                    <td><div>
                      <img src={!isNaN(testNetToMainNetIcons[item.tokenInType as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[item.tokenInType as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} alt="" />
                      {item.tokenInAmount} {getAsaNameById(item.tokenInType).toUpperCase()}</div></td>
                    {console.log(testNetToMainNetIcons[0 as keyof typeof testNetToMainNetIcons])}
                    <td><div>
                      <img src={!isNaN(testNetToMainNetIcons[item.tokenOutType as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[item.tokenOutType as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} alt="" />
                      {item.tokenOutAmount} {getAsaNameById(item.tokenOutType).toUpperCase()}</div></td>
                    <td>{item.time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='trade-footer'>
            <Link to="/">{FooterLogo}</Link>
            <div className='trade-footer-social'>
              <a href="#" target="_blank">{twitter}</a>
              <a href="https://www.reddit.com/r/MidaFi" target="_blank" rel="noreferrer">{reddit}</a>
              <a href="https://discord.gg/MprgABhtRV" target="_blank" rel="noreferrer">{discord}</a>
              <Link to="/contact" className='footer-contact'>Contact us</Link>
            </div>
          </div>
        </div>
        <div className={`right-side ${isHamburger ? 'active' : ''}`} ref={rightRef}>
          <div className="inner-container">
            <TradeType selected={tradeType} list={tradeTypeList} handleClick={(item) => {setTradeType(item); setValue1(null); setValue2(null); setValue3(null); if(tradeType == "Burn"){setSelected2((assets?.filter((asset: any) => {lpTokens.includes(asset?.id)})[0]?.id) ?? 100636711)}}} handleCreatePool={() => assets ? setPoolModal(true) : toast.error("Please connect a wallet first!")} />
            {tradeType == "Burn" ? <SwapForm
              selected={selected2}
              list={list1}
              balance={assets ? Number(assets.find(x => x.id === selected2)?.amount) / 10 ** getDecimalsById(selected2) : 0}
              usdPrice={27.5}
              value={value3}
              handleClick={() => selectToken3()}
              handleChange={(item: any) => {inputValue3(item);}}
              /> : <SwapForm
              selected={selected}
              list={list1}
              balance={assets ? Number(assets.find(x => x.id === selected)?.amount) / 10 ** getDecimalsById(selected) : 0}
              usdPrice={27.5}
              value={value1}
              handleClick={() => selectToken1()}
              handleChange={(item: any) => {inputValue1(item); setLastEdited(1)}}
            />}
            <div className="switch-btn-wrap">
              <div className="dot-line" />
              <button className="switch-btn" onClick={handleSwapSwitch}>
                {tradeType === 'Mint' ? PlusIcon : SwitchIcon}
              </button>
              <div className="dot-line" />
            </div>
            <SwapForm
              selected={selected1}
              list={list2}
              minReceived={tradeType != 'Mint' ? Number(value2) * (100 - slippage) / 100 : 0}
              balance={tradeType == 'Mint' ? (assets ? Number(assets.find(x => x.id === selected1)?.amount) / 10 ** getDecimalsById(selected) : 0) : 0}
              value={value2}
              handleClick={() => {if(tradeType != 'Burn') selectToken2()}}
              handleChange={(item: any) => {inputValue2(item); setLastEdited(2)}}
            />
            {tradeType === 'Burn' &&
              <div className='burn_form'>
                <SwapForm
                  selected={selected}
                  list={list2}
                  minReceived={Number(value1) * (100 - slippage) / 100}
                  value={value1}
                  handleClick={() => {if(tradeType != 'Burn') selectToken1()}}
                  handleChange={(item: any) => {inputValue1(item); setLastEdited(1)}}
                />
              </div>
            }
            <div className="detail-wrap">
              {tradeType === 'Swap' ?
                <AdvancedDetail
                  asa0={String(asas.find(x => x.id === selected)?.unit_name)}
                  asa1={String(asas.find(x => x.id === selected1)?.unit_name)}
                  ratio={Number(calculateSwap(1, selected, selected1)) / 10 ** getDecimalsById(selected)}
                  impact={calcImpact(selected, selected1, value1 ?? 0)}
                  //swapFee={feeTable[poolInfo?.f - 1]}
                  swapFee={Math.round(Number(value1) * (1 - feeTable[poolInfo?.f - 1]) * 10 ** getDecimalsById(selected))/10 ** getDecimalsById(selected)}
                  output={output}
                  setOutput={setOutput}
                />
                : tradeType === 'Mint' ? <AdvancedDetail ratio={4.87921} poolPercent={4} minLPreceived={/*2.305*/lpOut * (100 - slippage) / 100 } output={output} setOutput={setOutput} poolFeeTier={poolFeeTier} setPoolFeeTier={(tier) => {setPoolFeeTier(tier)}} />
                  : <AdvancedDetail ratio={4.87921} lpBurned={27} output={output} setOutput={setOutput} />
              }
              
              <button className="submit" onClick={() => handleSubmit()} disabled={confirmedState != 0}>
                { (confirmedState != 0 || isWaitingForTx) ? <div className='loading'/> : assets && (!(assets.find(asset => asset.id === selected)) || !(assets.find(asset => asset.id === selected1)) || (tradeType == 'Mint' && poolInfo && !(assets.find(asset => asset.id === poolInfo.lp)))) ? "Opt-in" : (poolInfo == null && tradeType == "Mint" ? 'Create Pool' : 'Submit') }
              </button>
            </div>
            {/* <BalanceCard />
            <BalanceCard />
            <BalanceCard />
            <BalanceCard /> */}
          </div>
        </div>
        {
          isTokenModal &&
          <div className='token-modal' ref={modalRef} >
            <h2>Select a token</h2>
            <div className='search'>
              {searchIcon}
              <input type="text" placeholder='Search by name or address' onChange={async e => setAsaFilter(e.target.value)}/>
            </div>
            {tradeType != 'Burn' && <div className='main-tokens'>
              {mainAsas.map((item, index) => (
                <div key={`mainToke-${index}`} onClick={() => selectSwapToken(item.id)}>
                  <img src={!isNaN(testNetToMainNetIcons[item.id as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[item.id as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} alt={item.name} />
                  <p>{item.unit_name}</p>
                </div>
              ))}
            </div>}
            <div className='token-list'>
              {(tradeType == 'Burn' ? (assets as Array<any>).filter((asset: any) => filterASAs(asset, asaFilter) && lpTokens.includes(asset.id))  : (asaFilter ? searchedASAs : popAsas))?.map((item: any, index: number) => (
                <div key={`token-${index}`} onClick={() => {selectSwapToken(item.id); setAsaFilter('')}}>
                  <img src={!isNaN(testNetToMainNetIcons[item.id as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[item.id as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} alt={item.name} />
                  <div>
                    <h2>{item.unit_name}</h2>
                    <p>{`${item.name} ${item.id == 0 ? '' : ' - ' + item.id}`}</p>
                  </div>
                </div>
              ))}
            </div>
            <button>
              Manage Token List
            </button>
          </div>
        }
        {confirmedState != 0 && <TxConfirmModal txns={lastTx} confirmedState={confirmedState} setConfirmedState={setConfirmedState} setLastTx={setLastTx}/>}
        {poolModal && <PoolModal handleExit={() => setPoolModal(false)} selectedToken1={poolCreateToken1} setSelectedToken1={setPoolCreateToken1} selectedToken2={poolCreateToken2} setSelectedToken2={setPoolCreateToken2} feeTier={poolFeeTier} setFeeTier={setPoolFeeTier} handleCreatePool={handleCreatePool} assets={assets}/>}
      </div>
    </>
  )
}

export default Trade
