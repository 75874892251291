import React, { useEffect, useRef, useState } from "react";
import { DEFAULT_IMG } from "src/constants";
import { SwapProps } from "src/types";
import { testNetToMainNetIcons } from "../../constants";
import { getAsaNameById } from "../../pages/Trade";
import { arrowDropdown } from "../Icons";
import "./swapform.scss";

const SwapForm = ({ selected, list, balance, usdPrice, value, minReceived, disabled = false, handleClick, handleChange }: SwapProps) => {

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = DEFAULT_IMG;
    event.currentTarget.className = 'error';
  };

  const handleBalanceClick = () => {
      handleChange(balance);
  }

  return (
    <div className="swapform">
      <div className="flex items-center justify-between">
        <div className="dropdown-wrap">
          <button onClick={handleClick} className="selected">
            <img src={!isNaN(testNetToMainNetIcons[selected as keyof typeof testNetToMainNetIcons]) ? `https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[selected as keyof typeof testNetToMainNetIcons]}/icon.png` : DEFAULT_IMG} />
            {getAsaNameById(selected)}
          </button>
        </div>
        <input type="number" disabled={disabled} onChange={(e) => handleChange(e.target.value)} value={value ? +(Number(value)).toFixed(5) : ''} placeholder='0.0'/>
      </div>
      <div className="balance" onClick={handleBalanceClick}>
        {balance ? `Balance: ${balance}` : ''}
      </div>
      <div className="price">
        {/* {usdPrice && `~$${usdPrice.toFixed(2)}USD`} */}
        {minReceived  ? `Min Received: ${minReceived?.toFixed(5)}` : ''}
      </div>
    </div>
  )
}

export default SwapForm