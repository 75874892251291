// this is a component file
import React, { useState, useRef, useEffect, useContext } from 'react';
import Switch from 'react-switch';
import { Link, useLocation } from 'react-router-dom';
import { useAccountContext } from 'src/context/context';
import { shortenAddress } from 'src/Utils';
import { ThemeContext } from 'src/theme/ThemeProvider';
import { algoIcon, settingIcon, signerIcon, peraIcon, algowalletIcon, arrowDropdown, moonIcon, moon, sun } from '../Icons';
import './header.scss';


const HeaderComponent = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const settingRef = useRef<HTMLDivElement>(null)
  const walletRef = useRef<HTMLDivElement>(null)

  const slippageList = [0.1, 0.5, 1]

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')

  const { addresses, ip, assets, walletType, slippage, setSlippage, connectToMyAlgo, connectAlgoSigner, connectToMobileWallet, disconnectWallet } = useAccountContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSetting, setSetting] = useState<boolean>(false)
  const [customSlippage, setCustomSlippage] = useState<number>(0.1)

  const handleClickOutside = (event: any) => {
    if (settingRef.current && !settingRef.current.contains(event.target)) {
      if (isSetting) setSetting(false)
    }
  }

  const handleWalletOutside = (event: any) => {
    if (walletRef.current && !walletRef.current.contains(event.target)) {
      if (isOpen) setIsOpen(false)
    }
  }

  const selectCustomSlippage = () => {
    if (customSlippage < 0) {
      setSlippage(0.1)
      setCustomSlippage(0.1)
    } else if (customSlippage > 100) {
      setSlippage(100)
      setCustomSlippage(100)
    } else {
      setSlippage(customSlippage)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    document.addEventListener('click', handleWalletOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
      document.removeEventListener('click', handleWalletOutside, false)
    }
  })

  return (
    <>
      <div className="navbar">
        <div className="flex items-center">
          <Link to="/">
            <div className="logo">
            </div>
          </Link>
          <div className="menu">
            <ul>
              <li>
                <Link
                  to="/trade"
                  className={splitLocation[1] === 'trade' ? 'active' : ''}
                >
                  Trade
                </Link>
              </li>
              <li>
                <Link
                  //to="/invest"
                  to="/"
                  className={splitLocation[1] === 'invest' ? 'active' : ''}
                  
                >
                  Invest
                </Link>
              </li>
              <li>
                <Link
                  //to="/analytics"
                  to="/"
                  className={splitLocation[1] === 'analytics' ? 'active' : ''}
                >
                  Analytics
                </Link>
              </li>
              <li>
                <Link
                  //to="/account"
                  to="/"
                  className={splitLocation[1] === 'account' ? 'active' : ''}
                >
                  Account
                </Link>
              </li>
              <li>
                <Link
                  //to="/faq"
                  to="/"
                  className={splitLocation[1] === 'faq' ? 'active' : ''}
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="connect-group">
          <button className="setting" onClick={() => setSetting(!isSetting)}>
            {settingIcon}
          </button>
          <button type="button" className="wallet-btn" onClick={() => setIsOpen(!isOpen)}>
            {addresses[0] === '' || undefined ?
              <>
                <img src="images/walletIcon.png" alt="wallet" />
                <span>Connect Wallet</span>
              </>
              :
              <>
                <div className="algoIcon">
                  {walletType === 'myalgo' ? algoIcon : walletType === 'algoSigner' ? signerIcon : peraIcon}
                </div>
                {shortenAddress(addresses[0])}
              </>
            }
          </button>
          {/* <button className="account" onClick={() => setIsAccount(!isAccount)}>
            <div className="avatar" style={{ backgroundImage: 'url(images/person.png)' }} />
            <div>Emily Tucson</div>
            <div className={`arrow ${isAccount ? 'toggle-up' : 'toggle-down'}`}>
              {arrowDropdown}
            </div>
          </button> */}
        </div>
      </div>
      {isSetting &&
        <div className='setting-modal' ref={settingRef}>
          <h6>Theme</h6>
          <div className='theme'>
            <div className='themeIcon'>
              {moon}
              <p>Dark</p>
            </div>
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                toggleTheme()
              }}
              checked={theme === 'light'}
              height={22}
              width={36}
              handleDiameter={19}
            />
            <div className='themeIcon'>
              {sun}
              <p>Light</p>
            </div>
          </div>
          <div>
            <h6>Slippage Tolerance</h6>
            <div className='slippage'>
              {slippageList.map((data, index) => (
                <h5
                  key={`slippage-${index}`}
                  className={data == slippage   ? 'active' : ''}
                  onClick={() => setSlippage(data)}
                >
                  {`${data}%`}
                </h5>))}
            </div>
            <div className='select-tolerance'>
              <input type="number" value={customSlippage ? customSlippage : ''} placeholder='Input' onChange={(e: any) => setCustomSlippage(e.target.value)} />
              <button onClick={() => selectCustomSlippage()}>Select</button>
            </div>
            <p className='comment'>Your transaction will revert if the price changes unfavorably more than this percentage.</p>
          </div>
        </div>
      }
      {isOpen && (addresses[0] === '' ?
        <div className="wallet-modal" ref={walletRef}>
          <div className="modal-body">
            <button className="wallet-btns" onClick={() => { connectToMyAlgo(); setIsOpen(false); }}>
              <div className="icons">
                {algoIcon}
              </div>
              My Algo
            </button>
            <button className="wallet-btns" onClick={() => { connectAlgoSigner(); setIsOpen(false) }}>
              <div className="icons">
                {signerIcon}
              </div>
              Algosigner
            </button>
            <button className="wallet-btns" onClick={() => { connectToMobileWallet(); setIsOpen(false) }}>
              <div className="icons">
                {peraIcon}
              </div>
              Pera Algo
            </button>
          </div>
        </div> :
        <div className="wallet-modal" ref={walletRef}>
          <div className="modal-body">
            <button className="wallet-btns" onClick={() => { disconnectWallet(); setIsOpen(false) }}>
              <div className="icons">
                {walletType === 'myalgo' ? algoIcon : walletType === 'algoSigner' ? signerIcon : peraIcon}
              </div>
              Disconnect
            </button>
          </div>
        </div>)
      }
    </>
  )
}

export default HeaderComponent
