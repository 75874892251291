import React, { useState } from "react"
import { percentageIcon } from "src/components/Icons"
import { isComputedPropertyName } from "typescript"

interface AccordionProp {
  item: any,
  index: number
}

const Accordion = ({ item, index }: AccordionProp) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  return (
    <>
      <tr onClick={() => setIsCollapsed(!isCollapsed)} className='cursor-pointer'>
        <td width="5%">{index + 1}</td>
        <td width="40%">{item.name}</td>
        <td width="40%">{item.price}</td>
        <td className='flex justify-start'>
          <div className='percentage'>
            ({item.change}%)
            {percentageIcon}
          </div>
        </td>
      </tr>
      <tr className={`pool-detail ${isCollapsed ? 'collapsed' : ''}`}>
        <td colSpan={4} className='inner-table'>
          <div>
            <table>
              <tr>
                <th>Pool</th>
                <th>Feer Tier</th>
                <th>TVL</th>
                <th>Volume(24h)</th>
                <th>Volume(7d)</th>
                <th></th>
              </tr>
              {item.detail.map((detail: any, index: number) => (
                <tr key={`detail-${index}`} >
                  <td>
                    <div>
                      <img src={`images/${detail.firstType}.svg`} />
                      {detail.firstQuantity}{' '}
                      {detail.firstType}
                      <img src={`images/${detail.secondType}.svg`} />
                      {detail.secondQuantity}{' '}
                      {detail.secondType}
                    </div>
                  </td>
                  <td>{detail.feeTier}%</td>
                  <td>{detail.tvl}</td>
                  <td>{detail.volume24}</td>
                  <td>{detail.volume7}</td>
                  <td>
                    <img src="images/plus.png" alt="plus" className='btns' />
                    <img src="images/switch_2.png" alt="switch" className='btns' />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </td>
      </tr></>)
}

export default Accordion