import React, { useState } from "react";
import { PoolProps } from "src/types";
import { testNetToMainNetIcons } from "../../constants";
import { fetchGetApi } from "../../Utils";
import { algodClient } from "../../Utils/connections";
import { arrowDropdown, CloseIcon, PlusIcon, SelectSymbol } from "../Icons";
import "./poolmodal.scss";

const feeTiers = [
  '0.3%',
  '1%',
  '0.5%',
  '0.1%',
  '0.05%',
  '0.01%'
]



const PoolModal = ({handleExit, selectedToken1, setSelectedToken1, selectedToken2, setSelectedToken2, feeTier, setFeeTier, handleCreatePool, assets}: PoolProps) => {


  const handleClickOutside = (e: React.MouseEvent) => {
    if(e.target === document.getElementById("pool-modal-background")) handleExit();
    else {
      if(focused1 && !(e.target === document.getElementById('search-token-1') || e.target === document.getElementById(`token-element`))) {
        setFocused1(false)
        setTokenSearch1('')
        setTokens1(assets.slice(0, 6))
      } else if(focused2 && !(e.target === document.getElementById('search-token-2') || e.target === document.getElementById(`token-element`))) {
        setFocused2(false)
        setTokenSearch2('')
        setTokens2(assets.slice(0, 6))
      }
    }
  }
  const [tokenSearch1, setTokenSearch1] = useState<string>("")
  const [tokenSearch2, setTokenSearch2] = useState<string>("")
  const [tokens1, setTokens1] = useState<any>(assets.slice(0, 6))
  const [tokens2, setTokens2] = useState<any>(assets.slice(0, 6))
  const [focused1, setFocused1] = useState<boolean>(false)
  const [focused2, setFocused2] = useState<boolean>(false)
  // const [selectedfeeTier, setSelectedfeeTier] = useState<number>(0)
  // const [selectedToken1, setSelectedToken1] = useState<any>(token1)
  // const [selectedToken2, setSelectedToken2] = useState<any>(token2)
  const filterASAs = (asa: any, asaFilter: string) => {
    if(!asa.unit_name.toLowerCase().includes(asaFilter.toLowerCase()) && !asa.id.toString().includes(asaFilter)) {
      return false
    }
    return true;
  }
  const handleSearchUpdate = async (value: any, index: number) => {

    if(index == 1) {
      setTokenSearch1(value)
      if(value) {
        // const response = await fetchGetApi(`searchASAs?s=${value}`)
        const response = assets.filter((asset: any) => filterASAs(asset, value))
        console.log(value, response)
        if(response.length > 0) {
          const firstSix = response.slice(0, 6)
          setTokens1(firstSix)
        } else if(Number(value)) {
          try {
            const res = await algodClient.getAssetByID(Number(value)).do()

            const token =  {
              id: res.index,
              amount: res.params.total,
              creator: res.params.creator,
              frozen: res.params['default-frozen'],
              decimals: res.params.decimals,
              name: res.params.name,
              unit_name: res.params['unit-name'],
              url: res.params.url,
            }
            setTokens1([token])

          } catch {
            setTokens1([])
          }
        } else {
          setTokens1([])
        }
        
        
      } else {
        setTokens1(assets.slice(0, 6))
      }
    } else {
      setTokenSearch2(value)
      if(value) {
        // const response = await fetchGetApi(`searchASAs?s=${value}`)
        const response = assets.filter((asset: any) => filterASAs(asset, value))
        console.log(value, response)
        if(response.length > 0) {
          const firstSix = response.slice(0, 6)
          setTokens2(firstSix)
        } else if(Number(value)) {
          try {
            const res = await algodClient.getAssetByID(Number(value)).do()

            const token =  {
              id: res.index,
              amount: res.params.total,
              creator: res.params.creator,
              frozen: res.params['default-frozen'],
              decimals: res.params.decimals,
              name: res.params.name,
              unit_name: res.params['unit-name'],
              url: res.params.url,
            }
            setTokens2([token])

          } catch {
            setTokens2([])
          }
          
        } else {
          setTokens2([])
        }
      } else {
        setTokens2(assets.slice(0, 6))
      }
    }





  }

  return (
    <div className="pool-modal-wrap" id="pool-modal-background" onClick={handleClickOutside}>
      <div className="pool-modal">
        <div className="modal-header">
          <h2>Create New Pool</h2>
          <button  onClick={handleExit}>
            {/* <img src="/images/exit.png" alt="exit" /> */}
            {CloseIcon}
          </button>
        </div>
        {selectedToken1 && <div className="main-token">
          <div>
            <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[selectedToken1.id as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
            <div>
              <h5>{selectedToken1.name}</h5>
              <h6>${selectedToken1.unit_name}</h6>
            </div>
          </div>
          <button>
            <img src="/images/pool_switch.png" alt="switch" onClick={e => setSelectedToken1(null)} />
          </button>
        </div>}
        { !selectedToken1 && <div className="select-token" id="asdasd">
          {/* <img src="/images/select_symbol.png" alt="select" /> */}
          {SelectSymbol}
          <input type='text' value={tokenSearch1} placeholder="Select a token, or paste the ID" id="search-token-1" onFocus={e => setFocused1(true)} onChange={e => {handleSearchUpdate(e.target.value, 1)}}/>
          
          {/* <img src="/images/arrowdown.png" alt="arrow" /> */}
          <span onClick={e => setFocused1(true)}>{arrowDropdown}</span>
        </div>}
        {focused1 && !selectedToken1 && <div className="search-token" >
            {tokens1?.map((item: any, index: number) => (
              <div key={`token-${index}`} className="token-element" id="token-element" onClick={e => {setSelectedToken1(item); setTokenSearch1('')}}>
                <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[item.id as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
                <h5>{item.unit_name} {item.id == 0 ? '' : ' - ' + item.id}</h5>
              </div>
            ))}

          </div>}
        <div className="feeTier">
          {feeTiers.map((data, index) => (
            <h5
              key={`feeTier-${index}`}
              className={feeTier - 1 === index ? 'active' : ''}
              onClick={() => setFeeTier(index + 1)}
            >
              {data}
            </h5>))}
          <div>
            {/* <img src="/images/pool_plus.png" alt="plus" /> */}
            {PlusIcon}
          </div>
        </div>
        {selectedToken2 && <div className="main-token">
          <div>
            <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[selectedToken2.id as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
            <div>
              <h5>{selectedToken2.name}</h5>
              <h6>${selectedToken2.unit_name}</h6>
            </div>
          </div>
          <button>
            <img src="/images/pool_switch.png" alt="switch" onClick={e => setSelectedToken2(null)} />
          </button>
        </div>}
        {!selectedToken2 && <div className="select-token">
          {/* <img src="/images/select_symbol.png" alt="select" /> */}
          {SelectSymbol}
          <input type='text' value={tokenSearch2} placeholder="Select a token, or paste the ID" id="search-token-2" onFocus={e => setFocused2(true)} onChange={e => {handleSearchUpdate(e.target.value, 2)}}/>
          
          {/* <img src="/images/arrowdown.png" alt="arrow" /> */}
          <span onClick={e => setFocused2(true)}>{arrowDropdown}</span>
        </div>}
        {focused2 && !selectedToken2 && <div className="search-token" >
            {tokens2?.map((item: any, index: number) => (
              <div key={`token-${index}`} className="token-element" id="token-element" onClick={e => {setSelectedToken2(item); setTokenSearch2('')}}>
                <img src={`https://asa-list.tinyman.org/assets/${testNetToMainNetIcons[item.id as keyof typeof testNetToMainNetIcons] ?? 0}/icon.png`} alt="algo" />
                <h5>{item.unit_name} {item.id == 0 ? '' : ' - ' + item.id}</h5>
              </div>
            ))}

          </div>}
        <button className="wallet-btn" onClick={handleCreatePool}>
          <p>Create Pool</p>
        </button>
      </div>
    </div>
  )
}

export default PoolModal