import React, { useState, useRef, useEffect, useContext } from 'react';
import { Logo } from 'src/components/Icons';
import './landing.scss';

const Landing = () => {

  return (
    <div className="video-container">
      <video autoPlay muted loop>
        <source src="/videos/purplegridbg.mp4" type='video/mp4'/>
      </video>
      {Logo}
    </div>
  )
}

export default Landing
