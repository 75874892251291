/* global AlgoSigner */
import React, { useState, createContext, useEffect, useContext } from 'react';
import MyAlgoConnect from '@randlabs/myalgo-connect';
import WalletConnect from "@walletconnect/client";
import WalletConnectQRCodeModal from "algorand-walletconnect-qrcode-modal";
import axios from 'axios';
import { IAssetData } from 'src/types';
import { apiGetAccountAssets } from 'src/Utils';

const myAlgoWallet = new MyAlgoConnect();

const walletList = [
  'myalgo',
  'algoSigner',
  'peraWallet'
]

declare global {
  interface Window {
    AlgoSigner: any;
  }
}
interface personalProps {
  addresses: string[];
  walletType: string;
  ip: string;
  assets: IAssetData[] | undefined;
  slippage: number;
  setSlippage: (item: number) => void;
  setAssets: (item: IAssetData[]) => void;
  connectToMyAlgo: () => void;
  connectAlgoSigner: () => void;
  connectToMobileWallet: () => void;
  disconnectWallet: () => void;
}

const Context = createContext<personalProps>({} as personalProps);

export const ContextProvider: React.FC = ({ children }) => {
  const [addresses, setAddresses] = useState<string[]>(['']);
  const [walletType, setType] = useState<string>('');
  const [assets, setAssets] = useState<IAssetData[]>();
  const [slippage, setSlippageState] = useState<number>(0.1);

  const [connector, setConnector] = useState<WalletConnect | null>(null);
  const [ip, setIp] = useState<string>('');

  const setSlippage = (item: number) => {
    setSlippageState(item);
    localStorage.setItem('midafiSlippage', String(item))
  }

  const setAddress = (address: string[]) => {
    setAddresses(address);
    localStorage.setItem('midafiAddress', address[0])
  }

  const setWalletType = (type: string) => {
    setType(type);
    localStorage.setItem('midafiWalletType', type)
  }

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIp(res.data.IPv4)
  }

  const connectToMyAlgo = async () => {
    try {
      const temp = await myAlgoWallet.connect();
      const address = temp.map(account => account.address)
      setAssets(await apiGetAccountAssets(address[0]))
      setAddress(address);
      setWalletType(walletList[0]);
    } catch (err) {
      console.error(err);
    }
  }

  const connectAlgoSigner = async () => {
    try {
      const resp = await window.AlgoSigner.connect()
      const account = await window.AlgoSigner.accounts({
        ledger: 'TestNet'
      })
      setAddress([account[0].address])
      setWalletType(walletList[1])
      setAssets(await apiGetAccountAssets(account[0].address))
    } catch (error) {
      console.log(error)
    }
  }

  const connectToMobileWallet = async () => {
    const temp = await new WalletConnect({
      bridge: "https://bridge.walletconnect.org",
      qrcodeModal: WalletConnectQRCodeModal,
    });

    setConnector(temp)

    if (!temp) {
      return;
    }

    if (!temp.connected) {
      // create new session
      await temp.createSession();
    }


    temp.on("session_update", async (error, payload) => {
      console.log(`connector.on("session_update")`);

      if (error) {
        throw error;
      }

      const { accounts } = payload.params[0];
    });

    temp.on("connect", async (error, payload) => {
      console.log(`connector.on("connect")`);
      const { accounts } = payload.params[0];
      const address = accounts[0];
      setAssets(await apiGetAccountAssets(address))
      setAddress([address])
      setWalletType(walletList[2])

      if (error) {
        throw error;
      }

    });

    temp.on("disconnect", (error, payload) => {
      console.log(`connector.on("disconnect")`);

      if (error) {
        throw error;
      }
    });

    if (temp.connected) {
      const { accounts } = await temp;
      console.log("Perawallet:", accounts)
      const address = await accounts[0];
      setAssets(await apiGetAccountAssets(address))
      setAddress([address])
      setWalletType(walletList[2])
    }
  };

  const disconnectWallet = () => {
    if (connector) {
      connector.killSession();
    }
    setAddress([''])
    setType('')
    localStorage.setItem('midafiWalletType', '')
  }

  const initialWalletConnect = async () => {
    try {
      const prevAddress = await localStorage.getItem('midafiAddress')
      console.log('prevAddress', prevAddress)
      const prevType = await localStorage.getItem('midafiWalletType')
      console.log('prevType', prevType)
      if (prevType && prevType !== '') {
        if (prevType === 'myalgo') {
          if (prevAddress) {
            setAssets(await apiGetAccountAssets(prevAddress))
            setAddresses([prevAddress])
            setWalletType(prevType)
          }
        } else if (prevType === 'algoSigner') {
          try {
            connectAlgoSigner();
          } catch (error: any) {
            console.log(error)
          }
        } else if (prevType === 'peraWallet') {
          connectToMobileWallet();
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSlippage = () => {
    try {
      const strSlippage = localStorage.getItem('midafiSlippage');
      if (strSlippage) setSlippageState(Number(strSlippage))
    } catch (error) {
      console.log(error)      
    }
  }

  useEffect(() => {
    getSlippage()
    getData();
    initialWalletConnect();
  }, [])

  return (
    <Context.Provider
      value={{ addresses, ip, assets, setAssets, slippage, setSlippage, walletType, connectToMyAlgo, connectAlgoSigner, connectToMobileWallet, disconnectWallet }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAccountContext = () => useContext(Context);
