import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ContextProvider } from './context/context';
import { ThemeProvider } from './theme/ThemeProvider';
import "./styles/index.scss";
import "../src/fonts/Gellix/stylesheet.css";

ReactDOM.render(
  <ContextProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </ContextProvider>
  ,
  document.getElementById('root')
);
