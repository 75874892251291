import React, { useEffect, useRef, useState } from "react";
import { TradeProps } from "src/types";
import { arrowDropdown } from "../Icons";
import "./tradeType.scss";

const TradeType = ({ selected, list, handleClick, handleCreatePool }: TradeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      if (isOpen) setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  })

  return (
    <div className="dropdown-container" ref={headerRef}>
      <button onClick={() => setIsOpen(!isOpen)} className="selected">
        {selected}
        <div className={`arrow ${isOpen ? 'toggle-up' : 'toggle-down'}`}>
          {arrowDropdown}
        </div>
      </button>
      {isOpen &&
        <div className="menu-container">
          {list.map((item, index) => (
            <button onClick={(e) => { e.preventDefault(); setIsOpen(false); handleClick(item) }} key={`tradeList-${index}`}>
              {item}
            </button>
          ))}
          <button onClick={(e) => handleCreatePool()} >
            Create Pool
          </button>
        </div>}
    </div>
  )
}

export default TradeType