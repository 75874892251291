import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom'
import Hero from 'src/components/Hero';
import Trade from 'src/pages/Trade';
import HeaderComponent from 'src/components/Header';
import Analytics from 'src/pages/Analytics';
import Landing from 'src/pages/LandingTemp';

const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/trade" element={<Trade />} />
        <Route path="/analytics" element={<Analytics />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router