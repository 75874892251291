import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import { ThemeContext } from 'src/theme/ThemeProvider';
import { ChartData } from 'src/types';
import './pricechart.scss';

declare type PageProps = {
  data: ChartData[];
  width?: string;
  height?: string;
  homeChart?: boolean;
  color?: string;
  min?: number;
  max?: number;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && typeof payload[0] !== 'undefined') {
    return (
      <div className="tooltip">
        <p>{moment(payload[0].payload.time).format('MMM Do h:mm A')}</p>
        <h5>${payload[0].payload.price.toFixed(2)}</h5>
      </div>
    );
  }

  return null;
};

export const PriceChart = ({
  data,
  width,
  height,
  homeChart,
  color,
  min,
  max
}: PageProps) => {
  const [isIncrease, setIsIncrease] = useState<boolean>(true);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setIsIncrease(data[0]?.price < data[data.length - 1]?.price);
    console.log('Increase decrease')
  }, [data]);

  return (
    <div className="chart-body" style={{ width: `${width ? width : 'auto'}px`, height: `${height ? height : 'auto'}px` }}>
      {data.length > 1 ?
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUvInc" x1="0" y1="0" x2="0" y2="1">
                <stop offset="60.43%" stopColor="#203AB5" stopOpacity={0.4} />
                <stop offset="100%" stopColor="rgba(32, 58, 181, 0.0001)" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorUvDec" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="60.43%"
                  stopColor={
                    theme === 'dark'
                      ? '#BE0000'
                      : '#FF2222'
                  }
                  stopOpacity={0.2}
                />
                <stop
                  offset="100%"
                  stopColor={
                    theme === 'dark'
                      ? '#BE0000'
                      : '#FF2222'
                  }
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            {!width && <Tooltip content={<CustomTooltip />} />}
            <YAxis domain={[min && min || 0, max && max || 1]} axisLine={false} tickLine={false} tickCount={5} hide />
            {/* <XAxis axisLine={false} tickLine={false} tickCount={10} /> */}
            <Area
              type="monotone"
              dataKey="y_position"
              isAnimationActive={false}
              stroke={
                homeChart
                  ? color
                  : isIncrease
                    ? '#203AB5'
                    : '#BE0000'
              }
              strokeWidth={theme === 'dark' ? 3 : 1}
              fill={isIncrease
                ? 'url(#colorUvInc)'
                : 'url(#colorUvDec)'
              }
            />
          </AreaChart>
        </ResponsiveContainer>
        :
        <div>
          <div className="loading">
          </div>
          <h3>Loading...</h3>
        </div>
      }
    </div>
  );
};
