export const POOL = "PMLJVRZMCIS5ZESXDEZX7YW7CZ4AISDISSAM7UMCX3O4Y6AYOXCSGTJ7M4";
export const ASSET_1 = 0;
export const ASSET_2 = 10458941;
export const LP = 83847527;
export const COMMUNITY = 123456;
export const CENTRAL_APP = 83847154;
export const SLIPPAGE = 0;
export const paramsWithFee = (params: any, f: any) => {
  const p = JSON.parse(JSON.stringify(params));
  p.flatFee = true;
  p.fee = f * 1000;
  return p;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sta = (str: string) => { return new Uint8Array(Buffer.from(str)); }
export const a1 = sta('swap')
export const a2 = Uint8Array.from([0])
export const SWAP_ARGS = [a1, a2];
export const MINT_ARGS = sta('mint')
export const BOOT_ARGS = [sta('bootstrap')]
export const BURN_ARGS = [sta('burn'), a2, a2]
export const ASSETS = [ASSET_1, ASSET_2, LP, COMMUNITY]
export const BASE_URL = 'https://us-east-1.aws.data.mongodb-api.com/app/midafi-live-guksd/endpoint'
export const MICROALGOS_TO_ALGOS_RATIO = 1e6
export const DEFAULT_IMG = 'images/Unkown_Token_1.svg'
type AssetType = {
  [key: string]: number
}
export const AssetIDs: AssetType = {
  'algo': 0,
  'eth': 16758227,
  '1inch': 13329513,
  'aave': 22171500,
  'amp': 80099546,
  'ant': 15740756,
  'ape': 67317087,
  'bal': 39759764,
  'band': 13329513,
  'bat': 45567391,
  'bnt': 13329513,
  'comp': 55269258,
  'dai': 77824624,
  'usdc': 10458941,
  'usdt': 12711317,
  'wbtc': 37048663,
  'weth': 37078613,
}
export const testNetToMainNetIcons = {
  10458941: 31566704,
  0: 0
}

export const CENTRAL_ADDR = "DLFFM3JUFZDAILYYGF6PCWIDZE3ALYFVP7J62IGFUOWYMTE5H76PTBKOOE"
export const AMM_ID = 100635609
export const COMMUNITY_TOKEN = 100540308